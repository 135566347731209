import React, { Component } from "react";
import {
  withStyles,
  Button,
  Typography,
  Grid,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { GetData } from "../../../api/service";
import ReactToPrint from "react-to-print";
import "./formprint.css";
import Barcode from "react-barcode";
import Loading from "../Forms/Loading";
import AlertDiv from "../Forms/AlertDiv";
import { orange } from "@material-ui/core/colors";
import MyAdmissionLanding from "../Admission/MyAdmissionLanding";

const styles = (theme) => ({});

class ViewForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoaded: false,
    };

    this.buttonRef = React.createRef();
  }

  getInitialData = () => {
    GetData(
      `/${this.props.user.email}/admissionform/getactivestudentsingle`
    ).then((resp) => {
      //console.log(resp[0])
      if (Array.isArray(resp) && resp.length > 0) {
        this.setState({
          data: resp[0],
          isLoaded: true,
        });
      }
    });
  };

  componentDidMount() {
    GetData(`/${this.props.user.email}/admissionform/hasreachedstep`).then(
      (resp) => {
        if (resp) {
          this.getInitialData();
        } else {
          this.props.history.replace("/home");
        }
      }
    );

    if (this.props.user.role == "RESTUDENT") {
      this.props.history.replace("/rehome");
    }
  }

  render() {
    let { classes } = this.props;
    return (
      <div>
        {this.state.isLoaded && (
          <MyAdmissionLanding
            user={this.props.user}
            data={this.state.data}
            history={this.props.history}
          />
        )}

        <div style={{ textAlign: "center" }}>
          <AlertDiv back={orange[100]} border={orange[400]}>
            You can print this form or can save as pdf. Click on the button
            below.


          </AlertDiv>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" size="small">
                Print or Save
              </Button>
            )}
            ref={(el) => (this.buttonRef = el)}
            content={() => this.componentRef}
            pageStyle={{ margin: "10px" }}
            copyStyles={true}
          />
          &nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => this.props.history.push(`/paymentresponse`)}
          >
            Download Payment Receipt
          </Button>
        </div>

        <br />
        <br />

        {this.state.isLoaded ? (
          <AdmissionForm
            ref={(el) => (this.componentRef = el)}
            {...this.state.data}
          />
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(ViewForm);

class AdmissionForm extends Component {
  render() {
    let total_marks = 0;
    let marks_secured = 0;
    return (
      <div className="admission-form">
        <table width="100%">
          <tbody>
            <tr>
              <td width="20%">
                <img
                  alt="logo"
                  src={"/images/logo.jpg"}
                  style={{ width: "100px" }}
                />
              </td>

              <td width="65%" align="center">
                <Typography variant="h5">Dispur College</Typography>
                <Typography variant="body2">
                  Ganeshguri, Guwahati, Assam, India, Pin 781006
                </Typography>

                <Typography variant="body1">
                  <b>Admission Form - {this.props.session_name}</b>
                </Typography>
              </td>

              <td width="15%" align="right">
                <Barcode
                  value={this.props.form_id}
                  height={40}
                  width={1.3}
                  margin={0}
                  displayValue={false}
                />
                <Typography variant="subtitle2">
                  Form No. : <b>{this.props.form_id}</b> <br />
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>

        <br />

        <Grid container spacing={24}>
          <Grid item lg={9} md={9} sm={9} xs={9}>
            <table width="100%">
              <tbody>
                <tr>
                  <td width="40%">
                    <Typography
                      style={{
                        fontWeight: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Class to which admission is sought
                    </Typography>
                  </td>
                  <td>
                    <Typography>:</Typography>
                  </td>
                  <td colSpan={`2`}>
                    <Typography variant="body2">
                      {this.props.course == "TDC" ? "FYUGP" : this.props.course}
                      -{this.props.stream}-1st{" "}
                      {this.props.course === "HS" ? `Year` : `Semester`}
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td width="40%">
                    <Typography
                      style={{
                        fontWeight: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Student's Unique ID
                    </Typography>
                  </td>
                  <td>
                    <Typography>:</Typography>
                  </td>
                  <td colSpan={`2`}>
                    <Typography variant="body2">
                      {this.props.unique_id}
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td width="40%">
                    <Typography
                      style={{
                        fontWeight: "18px",
                        fontWeight: 500,
                      }}
                    >
                      ARN No
                    </Typography>
                  </td>
                  <td>
                    <Typography>:</Typography>
                  </td>
                  <td colSpan={`2`}>
                    <Typography variant="body2">{this.props.arn_no}</Typography>
                  </td>
                </tr>

                <tr>
                  <td width="40%">
                    <Typography
                      style={{
                        fontWeight: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Aadhar Number
                    </Typography>
                  </td>
                  <td>
                    <Typography>:</Typography>
                  </td>
                  <td colSpan={`2`}>
                    <Typography variant="body2">
                      {this.props.aadhar_number}
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td width="40%" colSpan={`4`}>
                    <Typography
                      style={{
                        fontWeight: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Marks obtained in last examination
                    </Typography>
                  </td>
                </tr>
                <tr vAlign="bottom">
                  <td colSpan="2">
                    <table width="100%" className="table">
                      <tbody>
                        <tr>
                          <td width="50%">
                            <Typography>Total Marks</Typography>
                          </td>
                          <td>
                            <Typography variant="body2">
                              {this.props.total_marks}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td width="50%">
                            <Typography>Marks Obtained</Typography>
                          </td>
                          <td>
                            <Typography variant="body2">
                              {this.props.marks_obtained}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>

                  <td colSpan="2">
                    <table width="100%" className="table">
                      <tbody>
                        <tr>
                          <td width="50%">
                            <Typography>Percentage</Typography>
                          </td>
                          <td>
                            <Typography variant="body2">
                              {this.props.percentage}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td width="30%">
                            <Typography>Hostel Accomodation</Typography>
                          </td>
                          <td width="70%">
                            <Typography variant="body2">
                              {this.props.hostel}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>

          <Grid item lg={3} md={3} sm={3} xs={3} align="right">
            <div className="passport-photo">
              <ImageViewer data={this.props.dp} />
            </div>
          </Grid>
        </Grid>

        <table width="100%">
          <tbody>
            <tr>
              <td colSpan={4}>
                {this.props.course === "HS" ? (
                  <table width="100%" className="table">
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          <Typography
                            style={{
                              fontWeight: "18px",
                              fontWeight: 500,
                            }}
                          >
                            Subjects opted for
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="body2">
                            Compulsory: {this.props.compulsory_1.label},{" "}
                            {this.props.compulsory_2.label}
                          </Typography>
                        </td>

                        <td>
                          <Typography variant="body2">
                            MIL: {this.props.mil.label}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <Grid container>
                            {this.props.optional_subjects.map((el, index) => {
                              if (el != null) {
                                return (
                                  <Grid
                                    item
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={6}
                                    key={index}
                                  >
                                    <Typography variant="body2">
                                      {index + 1} : {el.label}
                                    </Typography>
                                  </Grid>
                                );
                              }
                            })}
                          </Grid>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : null}

                {this.props.course === 'TDC' && this.props.stream == "ARTS" ? (<table width="100%" className="table">

                  <tbody>
                    <tr>
                      <td colSpan="4">
                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                          Combination of Subject sougth
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography variant="body2" style={{ fontWeight: 700 }} gutterBottom>
                          Core Course
                        </Typography>

                        <Typography variant="body2">
                          Major Core: {this.props.major_1.label}
                        </Typography>
                        <Typography variant="body2">
                          Minor 1: {this.props.major_2.label}
                        </Typography>
                        <Typography variant="body2">
                          Minor 2: {this.props.major_3.label}
                        </Typography>
                      </td>


                      <td style={{ verticalAlign: 'top' }}>
                        {/* <Typography variant="body2" style={{ fontWeight: 700 }}>
                Multi-Disciplinary Course
            </Typography>


            <Typography variant="body2">
                {this.props.generic_1.label}
            </Typography> */}

                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                          Ability Enhancement Course
                        </Typography>


                        <Typography variant="body2">
                          {this.props.mil.label}
                        </Typography>
                        <br />

                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                          Value Added Course
                        </Typography>


                        <Typography variant="body2">
                          {this.props.generic_2.label}
                        </Typography>

                      </td>


                      
                    </tr>
                  </tbody>

                </table>) : null}
                {this.props.course === 'TDC' && this.props.stream == "COMMERCE" ? (<table width="100%" className="table">

                  <tbody>
                    <tr>
                      <td colSpan="4">
                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                          Combination of Subject sougth
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography variant="body2" style={{ fontWeight: 700 }} gutterBottom>
                          Core Course
                        </Typography>

                        <Typography variant="body2">
                          Major Core: {this.props.major_1.label}
                        </Typography>
                        <Typography variant="body2">
                          Minor 1: {this.props.compulsory_1.label}
                        </Typography>
                        <Typography variant="body2">
                          Minor 2: {this.props.compulsory_2.label}
                        </Typography>

                        <Typography variant="body2">
                          Minor 3: {this.props.compulsory_3.label}
                        </Typography>
                      </td>


                      <td style={{ verticalAlign: 'top' }}>
                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                          Ability Enhancement Course
                        </Typography>


                        <Typography variant="body2">
                          {this.props.mil.label}
                        </Typography>
                        <br />
                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                          Multi Disciplinary Course
                        </Typography>


                        <Typography variant="body2">
                          {this.props.generic_1.label}
                        </Typography>
                        <br />

                      </td>


                      <td style={{ verticalAlign: 'top' }}>


                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                          Value Added Course
                        </Typography>


                        <Typography variant="body2">
                          {this.props.generic_2.label}
                        </Typography>

                        <br />

                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                          Skill Enhancement Course
                        </Typography>


                        <Typography variant="body2">
                          {this.props.generic_3.label}
                        </Typography>


                      </td>



                    </tr>
                  </tbody>

                </table>) : null}

                {this.props.course === 'BBA' ? (<table width="100%" className="table">

                  <tbody>
                    <tr>
                      <td colSpan="4">
                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                          Combination of Subject sougth
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography variant="body2" style={{ fontWeight: 700 }} gutterBottom>
                          Core Course
                        </Typography>

                        <Typography variant="body2">
                          Core 1: {this.props.compulsory_1.label}
                        </Typography>
                        <Typography variant="body2">
                          Core 2: {this.props.compulsory_2.label}
                        </Typography>
                        <Typography variant="body2">
                          Core 3: {this.props.compulsory_3.label}
                        </Typography>


                      </td>





                      <td style={{ verticalAlign: 'top' }}>
                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                          Ability Enhancement Course
                        </Typography>


                        <Typography variant="body2">
                          {this.props.mil.label}
                        </Typography>
                        <br />
                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                          Multi Disciplinary Course
                        </Typography>


                        <Typography variant="body2">
                          {this.props.generic_1.label}
                        </Typography>
                        <br />

                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                          Value Added Course
                        </Typography>


                        <Typography variant="body2">
                          {this.props.generic_2.label}
                        </Typography>

                      </td>
                    </tr>
                  </tbody>

                </table>) : null}
              </td>
            </tr>
          </tbody>
        </table>

        {/* <table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td colSpan="6">
                                <Typography
                                    variant="h6"
                                    align="center"
                                >
                                    FOR OFFICE USE
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <Typography
                                    variant="body1"
                                >
                                    Date Of Admission
                                </Typography>
                            </td>

                            <td colSpan="4">
                                <Typography
                                    variant="body1"
                                >
                                    Class Admitted
                                </Typography>
                            </td>

                        </tr>

                        <tr>
                            <td colSpan="2">
                                <Typography
                                    variant="body1"
                                >
                                    Registration Type
                                </Typography>
                            </td>

                            <td colSpan="1">
                                <Typography
                                    variant="body1"
                                >
                                    New Student
                                </Typography>
                            </td>
                            <td colSpan="1">
                                <Typography
                                    variant="body1"
                                >
                                    Registered Student
                                </Typography>
                            </td>

                            <td colSpan="1">
                                <Typography
                                    variant="body1"
                                >
                                    Student Id
                                </Typography>
                            </td>
                            <td colSpan="1">
                                <Typography
                                    variant="body1"
                                >
                                    Roll No
                                </Typography>
                            </td>
                        </tr>



                        <tr>
                            <td colSpan="6" align="center">
                                <Typography
                                    variant="body1"
                                >
                                    Subjects Allotted
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>



                    </tbody>
                </table> */}

        <table width="100%" className="table">
          <tbody>
            <tr>
              <td width={`10%`}>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Applicant's Name
                </Typography>
              </td>
              <td width={`15%`}>
                <Typography variant="body2">{this.props.name}</Typography>
              </td>
              <td width={`10%`}>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Phone No
                </Typography>
              </td>
              <td width={`15%`}>
                <Typography variant="body2">{this.props.phone}</Typography>
              </td>
              <td width={`10%`}>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Email
                </Typography>
              </td>
              <td width={`15%`}>
                <Typography variant="body2">{this.props.email}</Typography>
              </td>

              <td width={`10%`}>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  WhatsApp No
                </Typography>
              </td>
              <td width={`15%`}>
                <Typography variant="body2">
                  {this.props.whatsapp_no}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Gender
                </Typography>
              </td>
              <td>
                <Typography variant="body2">{this.props.gender}</Typography>
              </td>

              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Date of Birth
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.good_date_of_birth}
                </Typography>
              </td>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Age as on 01-01-2022
                </Typography>
              </td>
              <td colSpan={3}>
                <Typography variant="body2">{this.props.age}</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Caste
                </Typography>
              </td>
              <td>
                <Typography variant="body2">{this.props.caste}</Typography>
              </td>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Nationality
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.nationality}
                </Typography>
              </td>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Religion
                </Typography>
              </td>
              <td>
                <Typography variant="body2">{this.props.religion}</Typography>
              </td>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Maratial Status
                </Typography>
              </td>
              <td width="30%">
                <Typography variant="body2">
                  {this.props.marrital_status}
                </Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Domicile State
                </Typography>
              </td>
              <td width="30%">
                <Typography variant="body2">{this.props.state}</Typography>
              </td>

              <td colSpan={2}>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Differently abled
                </Typography>
              </td>
              <td colSpan={4} width="30%">
                <Typography variant="body2">
                  {this.props.differently_abled}
                </Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Father/Guardian's Name
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.father_name}
                </Typography>
              </td>

              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Qualification
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.father_qualification}
                </Typography>
              </td>

              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Profession
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.father_profession}
                </Typography>
              </td>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Phone
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.father_contact}
                </Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Mother Name
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.mother_name}
                </Typography>
              </td>

              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Qualification
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.mother_qualification}
                </Typography>
              </td>

              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Profession
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.mother_profession}
                </Typography>
              </td>
              <td>
                <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Phone
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {this.props.mother_contact}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%" className="table">
          <tbody>
            <tr>
              <td width="25%" style={{ verticalAlign: "top" }}>
                <Typography
                  style={{
                    fontWeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Bank Details
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontSize: "12px", fontWeight: 400 }}
                >
                  Bank Name: {this.props.bank_name}
                  <br />
                  Branch: {this.props.bank_branch}
                  <br />
                  A/C No: {this.props.bank_account}
                  <br />
                  IFSC: {this.props.bank_ifsc}
                  <br />
                  MICR Code: {this.props.micr_code}
                </Typography>
              </td>
              <td width="25%" style={{ verticalAlign: "top" }}>
                <Typography
                  style={{
                    fontWeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Present Address
                </Typography>

                <Typography
                  variant="body2"
                  style={{ fontSize: "12px", fontWeight: 400 }}
                >
                  Village/Town: {this.props.present_address.pre_village}
                  <br />
                  P.O: {this.props.present_address.pre_po}
                  <br />
                  Dist: {this.props.present_address.pre_dist}, State:{" "}
                  {this.props.present_address.pre_state}
                  <br />
                  Pin: {this.props.present_address.pre_pin}, Contact:{" "}
                  {this.props.present_address.pre_contact}
                </Typography>
              </td>

              <td width="25%" style={{ verticalAlign: "top" }}>
                <Typography
                  style={{
                    fontWeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Permanent Address
                </Typography>

                <Typography
                  variant="body2"
                  style={{ fontSize: "12px", fontWeight: 400 }}
                >
                  Village/Town: {this.props.permanent_address.per_village}
                  <br />
                  P.O: {this.props.permanent_address.per_po}
                  <br />
                  Dist: {this.props.permanent_address.per_dist}, State:{" "}
                  {this.props.permanent_address.per_state}
                  <br />
                  Pin: {this.props.permanent_address.per_pin}, Contact:{" "}
                  {this.props.permanent_address.per_contact}
                </Typography>
              </td>

              <td width="25%" style={{ verticalAlign: "top" }}>
                <Typography
                  style={{
                    fontWeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Local Guardians' Details
                </Typography>

                <Typography
                  variant="body2"
                  style={{ fontSize: "12px", fontWeight: 400 }}
                >
                  Name: {this.props.local_g}
                  <br />
                  Village/Town: {this.props.local_address.g_village}
                  <br />
                  P.O: {this.props.local_address.g_po}
                  <br />
                  Dist: {this.props.local_address.g_dist}, State:{" "}
                  {this.props.local_address.g_state}
                  <br />
                  Pin: {this.props.local_address.g_pin}, Contact:{" "}
                  {this.props.local_address.g_contact}
                  <br />
                  LAC: {this.props.permanent_address.per_lac}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>

        <table width="100%" className="table">
          <tbody>
            <tr>
              <td>
                <Typography
                  style={{
                    fontWeight: "18px",
                    fontWeight: 500,
                  }}
                >
                  Examination Passsed
                </Typography>
              </td>
              <td align="center">
                <Typography>Board / University</Typography>
              </td>
              <td align="center">
                <Typography>Roll &amp; No.</Typography>
              </td>
              <td align="center">
                <Typography>Year</Typography>
              </td>
              <td align="center">
                <Typography>Institution</Typography>
              </td>
            </tr>
            {this.props.exam_passed.map((el, index) => (
              <tr key={index}>
                <td align="center">{el.examination_passed}</td>
                <td align="center">{el.board}</td>
                <td align="center">{el.roll}</td>
                <td align="center">{el.year}</td>
                <td align="center">{el.institution}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {this.props.course === "TDC" ? (
          <div>
            <table className="table" width="100%">
              <tbody>
                <tr>
                  <td colSpan="5">
                    <Typography>
                      Best of three Science / Arts / Commerce Subjects
                    </Typography>
                  </td>
                </tr>
              </tbody>

              <tbody>
                <tr>
                  <td>
                    <Typography>Subjects</Typography>
                  </td>
                  {this.props.best_subjects.map((el, index) => (
                    <td key={index} align="right">
                      <Typography>{el.subject}</Typography>
                    </td>
                  ))}
                  <td align="right">
                    <Typography>Total</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>Total Marks</Typography>
                  </td>
                  {this.props.best_subjects.map((el, index) => {
                    total_marks = parseFloat(el.total_marks) + total_marks;
                    return (
                      <td key={index} align="right">
                        <Typography>{el.total_marks}</Typography>
                      </td>
                    );
                  })}
                  <td align="right">
                    <Typography>{total_marks}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>Obtained Marks</Typography>
                  </td>
                  {this.props.best_subjects.map((el, index) => {
                    marks_secured =
                      parseFloat(el.marks_secured) + marks_secured;
                    return (
                      <td key={index} align="right">
                        <Typography>{el.marks_secured}</Typography>
                      </td>
                    );
                  })}
                  <td align="right">
                    <Typography>{marks_secured}</Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}

        <div>
          <table className="table" width="100%">
            <tbody>
              <tr>
                <td colSpan={parseFloat(this.props.last_marks.length) + 2}>
                  <Typography
                    style={{
                      fontWeight: "18px",
                      fontWeight: 500,
                    }}
                  >
                    Marks Secured in the last examination passed
                  </Typography>
                </td>
              </tr>
            </tbody>

            <tbody>
              <tr>
                <td>
                  <Typography>Subjects</Typography>
                </td>
                {this.props.last_marks.map((el, index) => (
                  <td key={index} align="right">
                    <Typography>{el.subject}</Typography>
                  </td>
                ))}
                <td align="right">
                  <Typography>Total</Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>Total Marks</Typography>
                </td>
                {this.props.last_marks.map((el, index) => {
                  total_marks = parseFloat(el.total_marks) + total_marks;
                  return (
                    <td key={index} align="right">
                      <Typography variant="body2">{el.total_marks}</Typography>
                    </td>
                  );
                })}
                <td align="right">
                  <Typography variant="body2">
                    {this.props.total_marks}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>Obtained Marks</Typography>
                </td>
                {this.props.last_marks.map((el, index) => {
                  marks_secured = parseFloat(el.marks_secured) + marks_secured;
                  return (
                    <td key={index} align="right">
                      <Typography variant="body2">
                        {el.marks_secured}
                      </Typography>
                    </td>
                  );
                })}
                <td align="right">
                  <Typography variant="body2">
                    {this.props.marks_obtained}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td colSpan={this.props.last_marks.length} align="right">
                  <Typography variant="body2">Percentage</Typography>
                </td>
                <td colSpan="2">
                  <Typography variant="body2">
                    {this.props.percentage}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* {this.props.course === 'HS' ? (

    <table width="100%" className="table">

        <tbody>
            <tr>
                <td colSpan="4">
                    <Typography>
                        14. Combination of Subjects sougth for HS Course
                </Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography
                        variant="body2"
                    >
                        Compulsory: {this.props.compulsory_1.label}
                    </Typography>
                </td>

                <td colSpan="2">
                    <Typography
                        variant="body2"
                    >
                        MIL: {this.props.mil.label}
                    </Typography>
                </td>
            </tr>
            <tr>
                <td colSpan="4">
                    <Grid container spacing={16}>
                        {this.props.optional_subjects.map((el, index) =>

                            <Grid item lg={6} md={6} sm={6} xs={6} key={index}>
                                <Typography variant="body2" >
                                    {index + 1} : {el.label}
                                </Typography>
                            </Grid>

                        )}
                    </Grid>
                </td>
            </tr>
        </tbody>

    </table>) : null}


<br /> */}

        <table className="table" width="100%">
          <tbody>
            <tr>
              <td width="20%">
                <Typography>Extra-Curricular Activity</Typography>
              </td>
              <td width="30%">
                <Typography variant="body2">{this.props.extra_curr}</Typography>
              </td>

              <td width="20%">
                <Typography>Computer Course</Typography>
              </td>
              <td width="30%">
                <Typography variant="body2">
                  {this.props.computer_course}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>

        <table className="table" width="100%">
          <tbody>
            <tr>
              <td width="20%">
                <Typography>Activities Enrollment</Typography>
              </td>
              <td width="80%" colSpan={3}>
                {Array.isArray(this.props.activities) &&
                  this.props.activities.length > 0 ? (
                  <FormGroup row>
                    {this.props.activities.map((el) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={el.status}
                            name={el.value}
                            color="primary"
                            readOnly={true}
                          />
                        }
                        label={el.label}
                      />
                    ))}
                  </FormGroup>
                ) : (
                  "Not found"
                )}
              </td>
            </tr>
            <tr>
              <td width="20%">
                <Typography>Shift</Typography>
              </td>
              <td width="30%">
                <Typography variant="body2">{this.props.shift}</Typography>
              </td>
              <td width="20%">
                <Typography>Do you have Gap ?</Typography>
              </td>
              <td width="30%">
                <Typography variant="body2">{this.props.has_gap}</Typography>
              </td>

              {this.props.has_gap === "YES" && (
                <React.Fragment>
                  <td width="20%">
                    <Typography>
                      Have you taken admission in any institute/college in
                      previous years ?
                    </Typography>
                  </td>
                  <td width="30%">
                    <Typography variant="body2">
                      {this.props.gap_admission}
                    </Typography>
                  </td>
                </React.Fragment>
              )}
            </tr>

            {this.props.has_gap === "YES" &&
              this.props.gap_admission === "YES" && (
                <React.Fragment>
                  <tr>
                    <td width="20%">
                      <Typography>
                        Gap Course Name: {this.props.gap_course}
                      </Typography>
                    </td>
                    <td width="30%">
                      <Typography>
                        Gap Institution: {this.props.gap_institute}
                      </Typography>
                    </td>

                    <td width="20%" colSpan={2}>
                      <Typography>
                        Gap Enrollment No/Reg. No: {this.props.gap_roll_no}
                      </Typography>
                    </td>
                  </tr>
                </React.Fragment>
              )}

            <tr>
              {this.props.has_gap === "YES" && (
                <React.Fragment>
                  <td width="20%">
                    <Typography>Reason</Typography>
                  </td>
                  <td width="30%" colSpan={3}>
                    <Typography variant="body2">
                      {this.props.gap_reason}
                    </Typography>
                  </td>
                </React.Fragment>
              )}
            </tr>
          </tbody>
        </table>

        <table className="table" width="100%">
          <tbody>
            <tr>
              <td colSpan="2">
                <Typography>Declaration from the Applicant</Typography>

                <Typography variant="body2">
                  &nbsp; &nbsp; &nbsp; &nbsp; I , {this.props.name} hereby
                  declare that I shall abide by the rules and regulations of the
                  College and shall be ready to accept any punishment which the
                  authority deems fit for me if there is any violation of the
                  rules by me. I do hereby declare that the statements made
                  above are true to the best of my knowledge and belief.
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "bottom",
                  }}
                >
                  <div>
                    <Typography variant="body2">Date:</Typography>
                  </div>
                  <div>
                    <div className="signature-photo">
                      <ImageViewer data={this.props.signature} />
                    </div>
                    <Typography variant="body2" style={{ float: "right" }}>
                      Full Signature of the Applicant
                    </Typography>
                  </div>
                </div>

                <div style={{ fontWeight: 700 }}>
                  N.B: At the time of admission, a candidate shall have to produce hard
                  copies of the following documents: <br />
                  1. Online form.<br />
                  2. Registration Fees acknowledgement receipt. <br />
                  3. Photocopies of Marksheet and pass certificate along with the original copies. <br />
                  4. Three passport size photographs. <br />
                  5. Caste Certificate (if applicable).<br />
                </div>
              </td>
            </tr>
          </tbody>

          {/* <tbody>
                        <tr>
                            <td colSpan="2">
                                <Typography>
                                    Declaration of the Parent/Guardian
                                </Typography>



                                <Typography
                                    variant="body2"
                                >
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    I,_________________________________ parent/guardian of the above named student, do hereby declare that I shall bear all educational expenses of my son/daughter/ward during his/her period of study. I have gone through the Prospectus of the College and declare that I shall be responsible for the conduct of my son/daughter/ward during his/her stay at the College. If my ward absent from regular classes for 15 days without information or if he/she fails to meet the minimum percentage of attendance as per University/Council notification then the decision of the college authority will be final.
                                </Typography>

                                <br />
                                <br />
                                <Typography
                                    variant="body2"
                                >
                                    Date:
                                    <Typography
                                        variant="body2"
                                        style={{ float: 'right' }}>
                                        Full Signature of the Parent / Guardian
                                </Typography>
                                </Typography>

                            </td>
                        </tr>

                    </tbody> */}
        </table>

        <table width="100%" className="table">
          <tbody>
            <tr>
              <td colSpan="6">&nbsp;</td>
            </tr>

            <tr>
              <td colSpan="6">
                <Typography variant="body1">
                  <b> Checked By </b>
                </Typography>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <Typography variant="body1">
                  <b>Selected</b>
                </Typography>
              </td>

              <td colSpan="2">
                <Typography variant="body1">
                  <b>Rejected</b>
                </Typography>
              </td>
              <td colSpan="2">
                <Typography variant="body1">
                  <b>Admitted</b>
                </Typography>
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                <Typography variant="body1">
                  <b>Admission In Charge</b>
                </Typography>
              </td>
              <td colSpan="3">
                <Typography variant="body1">
                  <b>Principal</b>
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

class ImageViewer extends Component {
  state = {
    file_id: "",
    data: "",
    isLoaded: false,
  };

  __getFile = (file_id) => {
    GetData(`/${file_id}/getfilesingle`).then((resp) => {
      if (resp.hasOwnProperty("mime") && resp.hasOwnProperty("data")) {
        let file = "";
        if (
          resp.mime === "image/jpeg" ||
          resp.mime === "image/png" ||
          resp.mime === "image/jpg"
        ) {
          file = `data:${resp.mime};base64,${resp.data}`;
        }
        this.setState({
          data: file,
          isLoaded: true,
        });
      }
    });
  };

  componentDidMount() {
    let data = this.props.data;
    if (Array.isArray(data) && data.length > 0) {
      if (data[0].hasOwnProperty("file_id")) {
        this.__getFile(data[0].file_id);
      }
    }
  }

  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        {this.state.isLoaded ? (
          <img
            alt="sig-photo"
            src={this.state.data}
            style={{ width: "100%", height: "100%" }}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

import React, { Component } from "react";
import Navbar from "../Components/Navbar";
import "./css/homepage.css";
import Welcome from "../Components/Homepage/Welcome";
import { GetData, PostData } from "../api/service";
import {
  Grid,
  Typography,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Card,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import InstructionsDiv from "../Components/Homepage/InstructionsDiv";
import Login from "../auth_components/js/Login";
import NewLogin from "../Components/Homepage/NewLogin";
import BannerDiv from "../Components/BannerDiv";
import FooterDiv from "../Components/Homepage/FooterDiv";
import { lightBlue, blue } from "@material-ui/core/colors";
import ShowMdFiles from "../Components/Essentials/ShowMdFiles";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PrintReceipt from "../Components/PrintReceipt/PrintReceipt";
import { getGoodSemester } from "../helper/GlobalConstants";
import CallToAction from "@material-ui/icons/CallToAction";

class FeePaymentArrearContainer extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    email: "",
    type: "",
    course: "", // new
    semester: "", // new
    roll_no: "", // new
    university_roll_no: "",
    registration_no: "",
    name: "",
    remarks: "",
    father_name: "",
    course_type: "",
    data: [],
    isLoaded: false,
    isFeeStringLoaded: false,
    paymentData: [],
    fees_id: "",
    amount_to_be_paid: "",
    shift: "",
    showQr: false,
    qrLink: "",
    amount_paid: "",
    transaction_id: "",
    date_of_payment: "",
    errorUpi: "",
  };

  onChange = (e) => {
    if (e.target.name == "fees_id") {
      let id = e.target.value;
      let amount_to_be_paid = 0;

      let d = this.state.data.find((el) => el.id == id);
      if (d) {
        amount_to_be_paid = d.payment_amount;
      }
      this.setState({
        [e.target.name]: e.target.value,
        amount_to_be_paid: parseFloat(amount_to_be_paid).toFixed(2),
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onSubmit = () => {
    if (
      this.state.course != "" &&
      this.state.semester != "" &&
      this.state.email != "" && 
      this.state.type != ""
    ) {
      // search the database for the user in student table and fetch the data along with the payment receipts of previous payment.
      PostData(`/get-fees-master-arrear`, {
        course: this.state.course,
        semester: this.state.semester,
      }).then((resp) => {
      
        if (resp != false) {
          this.setState({
            data: resp,
            isLoaded: true,
            isFeeStringLoaded: false,
          });
        } else {
          this.setState({
            isLoaded: false,
            isFeeStringLoaded: false,
          });
          alert("No entry found");
        }
      });
    }
  };

  onFeeSubmit() {
    if (
      this.state.fees_id != "" &&
      this.state.amount_to_be_paid != "" &&
      this.state.shift != ""
    ) {
      // get the payment string and set up the payment method
      PostData(`/validatepayment`, {
        shift: this.state.shift,
        current_course: this.state.course,
      })
        .then((resp) => {
          if (resp && resp.status == true) {
            this.setState({
              showQr: true,
              qrLink: resp.data,
            });
          } else {
            alert(`You Cannot make payment now`);
            this.setState({
              showQr: false,
              qrLink: "",
            });
          }
        })
        .catch((err) => {
          this.setState({
            showQr: true,
            qrLink: "",
          });
        });
    }
  }

  validateUPITransactionNO(trid) {
    var reg = new RegExp("^[a-zA-Z0-9_]*$");
    var test = reg.test(trid);

    if (!test) {
      this.setState({
        errorUpi: "Please put the transaction id",
      });
    } else {
      this.setState({
        errorUpi: "",
      });
    }
    return test;
  }

  onSubmitFeeWithTransactionId() {
    if (
      this.state.fees_id != "" &&
      this.state.amount_to_be_paid != "" &&
      this.state.shift != "" &&
      this.state.amount_paid != "" &&
      this.state.transaction_id != "" &&
      this.state.email != "" &&
      this.state.course != "" &&
      this.state.semester != "" &&
      this.state.name != "" &&
      this.state.father_name != "" &&
      this.state.roll_no != "" &&
      this.state.university_roll_no != "" &&
      this.state.registration_no != "" &&
      this.state.type != "" &&
      this.state.remarks != "" &&
      this.state.date_of_payment != "" &&
      this.validateUPITransactionNO(this.state.transaction_id)
    ) {
      

      // update the receipt

      PostData(`/submitfeereceiptdata-arrear`, {
        transaction_id: this.state.transaction_id,
        amount_paid: this.state.amount_paid,
        fees_id: this.state.fees_id,
        date_of_payment: this.state.date_of_payment,
        type: this.state.type,
        email: this.state.email,
        course: this.state.course,
        semester: this.state.semester,
        course_type: this.state.course_type,
        shift: this.state.shift,
        remarks: this.state.remarks,
        name: this.state.name,
        father_name: this.state.father_name,
        roll_no: this.state.roll_no,
        university_roll_no: this.state.university_roll_no,
        registration_no: this.state.registration_no,
      }).then((resp) => {
        if (resp != false) {
          if (resp.status == true) {
            alert(resp.message);
            // redirect to print the same receipt

            window.open(
              `/print-receipt/${this.state.email}?receiptNo=${resp.receipt_no_new}`
            );
            window.location.reload();
          } else {
            alert(resp.message);
          }
        } else {
          alert("Failed to add receipt");
        }
      });
    }
  }

  render() {
    return (
      <div>
        <Navbar history={this.props.history} />

        <div className="cont">
          <Card>
            <CardHeader
              title="Fill up the details for Arrear Examination"
              subheader="After inserting the data you will be asked to pay the amount with QR Code"
            />
            <CardContent>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>Select Type of Fees Payment</div>
                  <select
                    className="form-control"
                    name="type"
                    value={this.state.type}
                    onChange={this.onChange}
                  >
                    <option value="">-- Select Fees Type --</option>
                   
                    <option value="EXAMINATION_FEE">Examination Fees</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>Your Email id</div>
                  <input
                    className="form-control"
                    placeholder="email id"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </Grid>

                

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>Select Course</div>
                  <select
                    className="form-control"
                    name="course"
                    value={this.state.course}
                    onChange={this.onChange}
                  >
                    <option value="">-- Select Course --</option>
                   
                    <option value="BA">BA</option>
                    <option value="BCOM">BCOM</option>
                    <option value="BBA">BBA</option>
                    <option value="BCA">BCA</option>
                    <option value="HSA">HS Arts</option>
                    <option value="HSC">HS Commerce</option>
                  </select>
                </Grid>


                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>Select Semester</div>
                  <select
                    className="form-control"
                    name="semester"
                    value={this.state.semester}
                    onChange={this.onChange}
                  >
                    <option value="">-- Select Semester --</option>
                   
                    <option value="1">1st</option>
                    <option value="2">2nd</option>
                    <option value="3">3rd</option>
                    <option value="4">4th</option>
                    <option value="5">5th</option>
                    <option value="6">6th</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>Select Course Type</div>
                  <select
                    className="form-control"
                    name="course_type"
                    value={this.state.course_type}
                    onChange={this.onChange}
                  >
                    <option value="">NONE</option>
                   
                    <option value="HONOURS">HONOURS</option>
                    <option value="REGULAR">REGULAR</option>
                    
                  </select>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>Select Arrear Batch</div>
                  <select
                    className="form-control"
                    name="remarks"
                    value={this.state.remarks}
                    onChange={this.onChange}
                  >
                    <option value="">-- Select Arrear Batch --</option>
                   
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    
                  </select>
                </Grid>


                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>Your Name</div>
                  <input
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>Father's Name</div>
                  <input
                    className="form-control"
                    placeholder="Father's Name"
                    name="father_name"
                    value={this.state.father_name}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>College Roll No</div>
                  <input
                    className="form-control"
                    placeholder="Roll No"
                    name="roll_no"
                    value={this.state.roll_no}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>University Roll No</div>
                  <input
                    className="form-control"
                    placeholder="University Roll No"
                    name="university_roll_no"
                    value={this.state.university_roll_no}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>University Registration No</div>
                  <input
                    className="form-control"
                    placeholder="Registration No"
                    name="registration_no"
                    value={this.state.registration_no}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.onSubmit()}
                    style={{ marginTop: 18 }}
                  >
                    Fetch Fees
                  </Button>
                </Grid>
              </Grid>


              {this.state.isLoaded && <Grid container spacing={24}>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <div>Select Type of Fees Payment</div>
                    <select
                      className="form-control"
                      onChange={this.onChange}
                      value={this.state.fees_id}
                      name="fees_id"
                    >
                      <option value="">-- Select Fees Type --</option>
                      {Array.isArray(this.state.data) &&
                        this.state.data.map((el, index) => (
                          <option key={index} value={el.id}>
                            {el.payment_head} for {getGoodSemester(el.semester)}{" "}
                            Semester of Rs. {el.payment_amount}
                          </option>
                        ))}
                    </select>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <div>Amount to be paid</div>
                    <input
                      className="form-control"
                      placeholder="Amount"
                      onChange={this.onChange}
                      value={this.state.amount_to_be_paid}
                      name="amount_to_be_paid"
                      readOnly={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <div>Select Shift</div>
                    <select
                      className="form-control"
                      onChange={this.onChange}
                      value={this.state.shift}
                      name="shift"
                    >
                      <option value="">-- Select Shift --</option>
                      {["Day Shift", "Evening Shift"].map((el, index) => (
                        <option key={index} value={el}>
                          {el}
                        </option>
                      ))}
                    </select>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginTop: 18 }}
                      onClick={() => this.onFeeSubmit()}
                    >
                      Proceed to Payment
                    </Button>
                  </Grid>
                </Grid>}
            </CardContent>
          </Card>

          

          <br />

          

          {this.state.showQr && this.state.shift == "Day Shift" && (
            <>
              <Card>
                <CardHeader
                  title={`Scan the QR and pay Rs. ${this.state.amount_to_be_paid}. `}
                  //subheader="Insert the transaction details below"
                />
                <CardContent>
                  <img src={this.state.qrLink} style={{ width: "50%" }} />
                </CardContent>
              </Card>
            </>
          )}

          {this.state.showQr && this.state.shift == "Evening Shift" && (
            <>
              <Card>
                <CardHeader
                  title={`Scan the QR and pay Rs. ${this.state.amount_to_be_paid}. `}
                  // subheader="Insert the transaction details below"
                />
                <CardContent>
                  <img src={this.state.qrLink} style={{ width: "50%" }} />
                </CardContent>
              </Card>
            </>
          )}

          {this.state.showQr && (
            <>
              <Card>
                <CardHeader
                  title={`Insert Transaction Details`}
                  subheader="Insert the transaction details below"
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <div>Amount Paid</div>
                      <input
                        className="form-control"
                        placeholder="Amount Paid"
                        onChange={this.onChange}
                        value={this.state.amount_paid}
                        name="amount_paid"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <div>Transaction ID (as mentioned in the UPI app)</div>
                      <input
                        className="form-control"
                        placeholder="Transaction ID"
                        onChange={this.onChange}
                        value={this.state.transaction_id}
                        name="transaction_id"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <div>Date of Payment</div>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Date of Payment"
                        onChange={this.onChange}
                        value={this.state.date_of_payment}
                        name="date_of_payment"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 18 }}
                        onClick={() => this.onSubmitFeeWithTransactionId()}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          )}
        </div>

        <FooterDiv />
      </div>
    );
  }
}

export default FeePaymentArrearContainer;

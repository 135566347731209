export const BaseURL = process.env.NODE_ENV === 'production' ? 'https://backend.dispurcollege.ac.in/public' : 'http://localhost/dispur-college/api/public';
export const AddOnUrl = process.env.NODE_ENV === 'production' ? 'https://studentbackend.dispurcollege.ac.in/public' : 'http://localhost/dispur-college/studentbackend/public';



export function getGoodSemester(semester){
    if(semester == 1){
        return "1st";
    }

    if(semester == 2){
        return "2nd";
    }

    if(semester == 3){
        return "3rd";
    }

    if(semester == 4){
        return "4th";
    }

    if(semester == 5){
        return "5th";
    }

    if(semester == 6){
        return "6th";
    }
}
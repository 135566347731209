import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, Grid, Typography, CardActions, Button, MenuItem, FormControl, Select, OutlinedInput, InputLabel } from '@material-ui/core';
import { blue, lightGreen, orange } from '@material-ui/core/colors';
import FileUpload from './FileUpload';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AlertDiv from './AlertDiv';
import { PostData } from '../../../api/service';

const styles = theme => ({
    buttonSubmit: {
        color: 'white',
        backgroundColor: blue[400],
        '&:hover': {
            color: blue[400],
            backgroundColor: 'white',
            border: `solid thin ${blue[400]}`
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: '#1715DA'
    },
    formControl: {
        width: '100%'
    }
})


class FormTwo extends Component {
    state = {
        course: '',
        caste: '',
        differently_abled: '',
        photo: [],
        signature: [],
        is_free_admission: 0,
        marksheet: [],
        pass_certificate: [],
        caste_certificate: [],
        gap_certificate: [],
        guardians_declaration: [],
        guardians_signature: [],
        
        registration_card: [],
        admission_receipt: [],
        examination_admit_card: [],

        age_proof: [],
        passbook: [],
        unique_ack: [],
        errors: [],
        is_migration: '',
        migration_certificate: [],
        any_achievement: '',
        achievement_certificate: [],
        income_certificate: [],
    }


    componentDidMount() {
        //console.log(this.props)
        if (this.props.edit) {
            let data = this.props.data;
            if (Array.isArray(data) && data.length > 0) {
                //set photo data
                this.setState({
                    caste: data[0].caste,
                    course: data[0].course,
                    differently_abled: data[0].differently_abled,
                    photo: data[0].dp,
                    signature: data[0].signature,
                    is_free_admission: data[0].is_free_admission,
                    passbook: data[0].passbook,
                    age_proof: data[0].age_proof,
                    marksheet: data[0].marksheet,
                    pass_certificate: data[0].pass_certificate,
                    caste_certificate: data[0].caste_certificate,
                    gap_certificate: data[0].gap_certificate,
                    guardians_declaration: data[0].guardians_declaration,
                    guardians_signature: data[0].guardians_signature,

                    passbook: data[0].passbook,
                    domicile_certificate: data[0].domicile_certificate,
                    pwd_certificate: data[0].pwd_certificate,
                    unique_ack: data[0].unique_ack,
                    registration_card: data[0].registration_card,
                    examination_admit_card: data[0].examination_admit_card,
                    admission_receipt: data[0].admission_receipt,
                    is_migration: data[0].is_migration,
                    migration_certificate: data[0].migration_certificate,
                    any_achievement: data[0].any_achievement,
                    achievement_certificate: data[0].achievement_certificate,
                    income_certificate: data[0].income_certificate,

                })
            }
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })


    }



    onChangePhoto = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    handleValidation = () => {
        let { marksheet, photo, signature, age_proof, passbook, unique_ack, errors, gap_certificate, registration_card, examination_admit_card, admission_receipt, domicile_certificate, differently_abled, pwd_certificate, caste_certificate, guardians_declaration, pass_certificate, caste, course, is_migration, migration_certificate, any_achievement, is_free_admission, income_certificate, achievement_certificate, guardians_signature } = this.state;
        let isValid = true;

        //console.log(isValid);

        if (course === 'PG') {
            if (is_migration === 'YES') {
                if (Array.isArray(migration_certificate) && migration_certificate.length === 0) {
                    isValid = false;
                    errors['migration_certificate'] = 'Please fill up this field'
                } else {
                    errors['migration_certificate'] = '';
                }
            }

            if (any_achievement !== '' && any_achievement !== null) {
                if (Array.isArray(achievement_certificate) && achievement_certificate.length === 0) {
                    isValid = false;
                    errors['achievement_certificate'] = 'Please fill up this field'
                } else {
                    errors['achievement_certificate'] = '';
                }
            }


            if (caste !== 'GENERAL') {
                if (Array.isArray(caste_certificate) && caste_certificate.length === 0) {
                    isValid = false;
                    errors['caste_certificate'] = 'Please fill up this field'
                } else {
                    errors['caste_certificate'] = '';
                }
            }

            if (differently_abled === 'YES') {
                if (Array.isArray(pwd_certificate) && pwd_certificate.length === 0) {
                    isValid = false;
                    errors['pwd_certificate'] = 'Please fill up this field'
                } else {
                    errors['pwd_certificate'] = '';
                }
            }

            if (Array.isArray(guardians_signature) && guardians_signature.length === 0) {
                isValid = false;
                errors['guardians_signature'] = 'Please fill up this field'
            } else {
                errors['guardians_signature'] = '';
            }
        }

        if (Array.isArray(photo) && photo.length === 0) {
            isValid = false;
            errors['photo'] = 'Please fill up this field'
        } else {
            errors['photo'] = '';
        }

        if (Array.isArray(signature) && signature.length === 0) {
            isValid = false;
            errors['signature'] = 'Please fill up this field'
        } else {
            errors['signature'] = '';
        }


        if(is_free_admission == 1){
            if (Array.isArray(income_certificate) && income_certificate.length === 0) {
                isValid = false;
                errors['income_certificate'] = 'Please fill up this field'
            } else {
                errors['income_certificate'] = '';
            }
        }


        // if (examination_admit_card == null) {
        //     isValid = false;
        //     errors['examination_admit_card'] = 'Please fill up this field'
        // } else {
        //     errors['examination_admit_card'] = '';
        // }

        // if (Array.isArray(examination_admit_card) && examination_admit_card.length === 0) {
        //     isValid = false;
        //     errors['examination_admit_card'] = 'Please fill up this field'
        // } else {
        //     errors['examination_admit_card'] = '';
        // }



        // if (admission_receipt == null) {
        //     isValid = false;
        //     errors['admission_receipt'] = 'Please fill up this field'
        // } else {
        //     errors['admission_receipt'] = '';
        // }

        // if (Array.isArray(admission_receipt) && admission_receipt.length === 0) {
        //     isValid = false;
        //     errors['admission_receipt'] = 'Please fill up this field'
        // } else {
        //     errors['admission_receipt'] = '';
        // }

        // if (age_proof === undefined) {
        //     isValid = false;
        //     errors['age_proof'] = 'Please fill up this field'
        // } else {
        //     errors['age_proof'] = '';
        // }

        // if (Array.isArray(age_proof) && age_proof.length === 0) {
        //     isValid = false;
        //     errors['age_proof'] = 'Please fill up this field'
        // } else {
        //     errors['age_proof'] = '';
        // }

        // if (passbook === undefined) {
        //     isValid = false;
        //     errors['passbook'] = 'Please fill up this field'
        // } else {
        //     errors['passbook'] = '';
        // }

        // if (Array.isArray(passbook) && passbook.length === 0) {

        //     isValid = false;
        //     errors['passbook'] = 'Please fill up this field'
        // } else {
        //     errors['passbook'] = '';
        // }


        if (course !== 'PG') {

            // if (unique_ack === undefined) {
            //     isValid = false;
            //     errors['unique_ack'] = 'Please fill up this field'
            // } else {
            //     errors['unique_ack'] = '';
            // }

            // if (Array.isArray(unique_ack) && unique_ack.length === 0) {

            //     isValid = false;
            //     errors['unique_ack'] = 'Please fill up this field'
            // } else {
            //     errors['unique_ack'] = '';
            // }

        }

        // if (Array.isArray(pass_certificate) && pass_certificate.length === 0) {
        //     isValid = false;
        //     errors['pass_certificate'] = 'Please fill up this field'
        // } else {
        //     errors['pass_certificate'] = '';
        // }



        // if (Array.isArray(domicile_certificate) && domicile_certificate.length === 0) {
        //     isValid = false;
        //     errors['domicile_certificate'] = 'Please fill up this field'
        // } else {
        //     errors['domicile_certificate'] = '';
        // }



        // console.log(income,marksheet,tree);
        // console.log(isValid);


        this.setState({
            errors
        })

        return isValid;

    }



    onSubmit = (e) => {
        e.preventDefault();

        if (this.handleValidation()) {
            let d = {
                email: this.props.email,
                dp: this.state.photo,
                signature: this.state.signature,
                is_free_admission: this.state.is_free_admission,
                marksheet: this.state.marksheet,
                age_proof: this.state.age_proof,
                passbook: this.state.passbook,
                gap_certificate: this.state.gap_certificate,
                pass_certificate: this.state.pass_certificate,
                guardians_declaration: this.state.guardians_declaration,
                caste_certificate: this.state.caste_certificate,
                domicile_certificate: this.state.domicile_certificate,
                pwd_certificate: this.state.pwd_certificate,
                unique_ack: this.state.unique_ack,
                examination_admit_card: this.state.examination_admit_card,
                admission_receipt: this.state.admission_receipt,
                registration_card: this.state.registration_card,
                migration_certificate: this.state.migration_certificate,
                achievement_certificate: this.state.achievement_certificate,
                guardians_signature: this.state.guardians_signature,
                income_certificate: this.state.income_certificate

            }

            this.props.setData(d);

            // confirmAlert({
            //     title: 'Final Submission',
            //     message: 'After clicking the Final Submission button, you cannot change your form details.',
            //     buttons: [
            //         {
            //             label: 'Final Submit',
            //             onClick: () => this.props.setData(d)
            //         },
            //         {
            //             label: 'Cancel'
            //         }
            //     ]
            // });

        }




    }


    fetchPreviousFormData = () => {
        PostData(`/admissionform/getpreviouscompletedformdata`, {}).then((resp) => {
            if (resp != false) {
                this.setState({
                    photo: resp.dp,
                    signature: resp.signature,
                    caste_certificate: resp.caste_certificate,
                    guardians_signature: resp.guardians_signature,
                })
            }
        })


    }

    render() {
        let { classes } = this.props;



        return (
            <div>
                <form onSubmit={this.onSubmit}>

                    <Typography
                        variant="h5"
                    >
                        Document Uploads
                    </Typography>
                    <Typography>
                        All <span className="red-alert">*</span> marked fields are mandatory
                    </Typography>



                    <br />

                   

                    <AlertDiv
                        back={orange[100]}
                        border={orange[400]}
                    >
                        <strong>Instructions !!</strong>

                        <ul>
                            <li><Typography>Upload a clean, scanned copy of your document</Typography></li>
                            <li><Typography>Only .jpg or .png extensions are allowed</Typography></li>
                            {/* <li><Typography>After Choosing the file, press "Upload" button.</Typography></li> */}
                            <li><Typography>After successful upload, the file will appear in the white box</Typography></li>
                        </ul>

                        <strong>Photograph</strong>
                        <ul>
                            <li><Typography>Photograph must be a recent passport size colour picture.</Typography></li>
                            <li><Typography>Dimensions 200 x 230 pixels (preferred)</Typography></li>
                        </ul>

                        <strong>Signature</strong>
                        <ul>
                            <li><Typography>Signature must be in a white paper with black ink.</Typography></li>
                            <li><Typography>Dimensions 240  x 100 pixels (preferred)</Typography></li>
                        </ul>




                    </AlertDiv>

                    <br />

                    <Button variant="contained" color="primary" onClick={() => this.fetchPreviousFormData()}>Click here to autofill the data from previous form</Button>

<br />
<br />


                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}
                                gutterBottom
                            >
                                Personal Documents
                            </Typography>
                            <Grid container spacing={24}>

                                <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['photo'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['photo']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}

                                            >
                                                Upload Your Photo (size below 500kb)  <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`ADMISSION_PHOTO`}
                                        apikey={this.props.user.apikey}
                                        onChange={this.onChangePhoto.bind(this, 'photo')}
                                        data={this.state.photo}
                                        limit={500}
                                    // width={200}
                                    // height={230}

                                    />




                                    {/* <AlertDiv
                                        back={orange[100]}
                                        border={orange[400]}
                                    >
                                       
                                       

                                    </AlertDiv> */}
                                </Grid>



                                <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['signature'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['signature']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Your Signature (size below 100kb)  <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`ADMISSION_SIGNATURE`}
                                        apikey={this.props.user.apikey}
                                        onChange={this.onChangePhoto.bind(this, 'signature')}
                                        data={this.state.signature}
                                        limit={100}
                                    // width={240}
                                    // height={100}
                                    />


                                </Grid>

                                <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['guardians_signature'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['guardians_signature']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Guardian's Signature (size below 100kb)  <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`ADMISSION_GUARDIANS_SIGNATURE`}
                                        apikey={this.props.user.apikey}
                                        onChange={this.onChangePhoto.bind(this, 'guardians_signature')}
                                        data={this.state.guardians_signature}
                                        limit={100}
                                    // width={240}
                                    // height={100}
                                    />


                                </Grid>


                                {this.state.is_free_admission == 1 && <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['income_certificate'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['income_certificate']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Income Certificate (issued by Local Revenue Circle or Ration card issued by Competent Authority)<span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`ADMISSION_INCOME_CERTIFICATE`}
                                        apikey={this.props.user.apikey}
                                        onChange={this.onChangePhoto.bind(this, 'income_certificate')}
                                        data={this.state.income_certificate}
                                        limit={100}
                                    // width={240}
                                    // height={100}
                                    />


                                </Grid>}


                                {/* <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['passbook'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['passbook']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload  photo of bank passbook (size below 500kb)  <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`ADMISSION_PASSBOOK`}
                                        apikey={this.props.user.apikey}
                                        onChange={this.onChangePhoto.bind(this, 'passbook')}
                                        data={this.state.passbook}
                                        limit={500}
                                    />

                                 
                                </Grid> */}


                                {/* <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['unique_ack'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['unique_ack']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Unique Id Acknowledgement (size below 500kb)
                                            </Typography>
                                        }
                                        type={`UNIQUE_ACK`}
                                        apikey={this.props.user.apikey}
                                        onChange={this.onChangePhoto.bind(this, 'unique_ack')}
                                        data={this.state.unique_ack}
                                        limit={500}
                                    />

                                 
                                </Grid> */}

                                {/* <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['age_proof'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['age_proof']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload age proof document (size below 500kb) <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`ADMISSION_AGE_PROOF`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'age_proof')}
                                        data={this.state.age_proof}
                                        limit={500}

                                    />

                                    
                                </Grid> */}


                                {this.state.caste !== 'GENERAL' && <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['caste_certificate'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['caste_certificate']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Caste Certificate (size below 500kb) <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`ADMISSION_CASTE_CERTIFICATE`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'caste_certificate')}
                                        data={this.state.caste_certificate}
                                        limit={500}

                                    />


                                </Grid>}

                                {this.state.course == 'PG' && this.state.differently_abled === 'YES' && <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['pwd_certificate'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['pwd_certificate']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload PWD Certificate (size below 500kb) <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`ADMISSION_PWD_CERTIFICATE`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'pwd_certificate')}
                                        data={this.state.pwd_certificate}
                                        limit={500}

                                    />
                                </Grid>}

                                {this.state.course == 'PG' && this.state.is_migration === 'YES' && <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['migration_certificate'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['migration_certificate']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Migration Certificate (size below 500kb) <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`ADMISSION_MIGRATION_CERTIFICATE`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'migration_certificate')}
                                        data={this.state.migration_certificate}
                                        limit={500}

                                    />
                                </Grid>}

                                {this.state.course == 'PG' && this.state.any_achievement != '' && this.state.any_achievement != null && <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['achievement_certificate'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['achievement_certificate']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Achievement Certificate (size below 500kb) <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`ADMISSION_ACHIEVEMENT_CERTIFICATE`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'achievement_certificate')}
                                        data={this.state.achievement_certificate}
                                        limit={500}

                                    />
                                </Grid>}


                                {/* <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['domicile_certificate'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['domicile_certificate']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Domicile Certificate (size below 500kb) (check the guideline for more details) <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`ADMISSION_DOMICILE_CERTIFICATE`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'domicile_certificate')}
                                        data={this.state.domicile_certificate}
                                        limit={500}

                                    />
                                </Grid> */}

                            </Grid>

                        </CardContent>
                    </Card>
                    <br />
                    {/*
                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}
                                gutterBottom

                            >
                                Educational Documents
                            </Typography>

                            <Grid container spacing={24}>







                                <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['examination_admit_card'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['examination_admit_card']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Examination Admit Card  (size below 500kb)  <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`EXAMINATION_ADMIT_CARD`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'examination_admit_card')}
                                        data={this.state.examination_admit_card}
                                        limit={500}

                                    />


                                </Grid>


                                <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['admission_receipt'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['admission_receipt']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Last year Admission Receipt  (size below 500kb)  <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`ADMISSION_RECEIPT`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'admission_receipt')}
                                        data={this.state.admission_receipt}
                                        limit={500}

                                    />


                                </Grid>


                                <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['registration_card'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['registration_card']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Registration Certificate  (size below 500kb)  
                                            </Typography>
                                        }
                                        type={`REGISTRATION_CARD`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'registration_card')}
                                        data={this.state.registration_card}
                                        limit={500}

                                    />


                                </Grid>


                              <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['pass_certificate'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['pass_certificate']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Pass Certificate (Testimonials) (size below 500kb)  <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`ADMISSION_PASS_CERTIFICATE`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'pass_certificate')}
                                        data={this.state.pass_certificate}
                                        limit={500}

                                    />


                                </Grid> 

<Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['gap_certificate'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['gap_certificate']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Gap Certificate (if any) (size below 500kb)
                                            </Typography>
                                        }
                                        type={`ADMISSION_GAP_CERTIFICATE`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'gap_certificate')}
                                        data={this.state.gap_certificate}
                                        limit={500}

                                    />


                                </Grid> 



                                <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['passbook'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['passbook']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload  photo of bank passbook (size below 500kb)  <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`ADMISSION_PASSBOOK`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'passbook')}
                                        data={this.state.passbook}
                                        limit={500}
                                    />

                                 
                                </Grid>

                                <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['unique_ack'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['unique_ack']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload  Unique Id Acknowledgement (size below 500kb)  <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`UNIQUE_ACK`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'unique_ack')}
                                        data={this.state.unique_ack}
                                        limit={500}
                                    />

                                 
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card> */}
                    <br />
                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        {/* <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}
                                gutterBottom

                            >
                                Declaration Documents
                            </Typography>
                            <Grid container spacing={24}>
                                <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['guardians_declaration'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['guardians_declaration']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Guardian's Declaration (size below 500kb)
                                            </Typography>
                                        }
                                        type={`ADMISSION_GUARDIANS_DECLARATION`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'guardians_declaration')}
                                        data={this.state.guardians_declaration}
                                        limit={500}

                                    />


                                </Grid>
                            </Grid>


                        </CardContent> */}

                        <CardActions className={classes.cardActions}>
                            <Button
                                type="button"
                                onClick={this.props.back}
                            >
                                Previous
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.buttonSubmit}
                                type="submit"
                            >
                                Save &amp; Proceed to next step
                            </Button>

                        </CardActions>
                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(FormTwo)
import React, { Component } from "react";
import Navbar from "../Components/Navbar";
import "./css/homepage.css";
import Welcome from "../Components/Homepage/Welcome";
import { GetData, PostData } from "../api/service";
import {
  Grid,
  Typography,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Card,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import InstructionsDiv from "../Components/Homepage/InstructionsDiv";
import Login from "../auth_components/js/Login";
import NewLogin from "../Components/Homepage/NewLogin";
import BannerDiv from "../Components/BannerDiv";
import FooterDiv from "../Components/Homepage/FooterDiv";
import { lightBlue, blue } from "@material-ui/core/colors";
import ShowMdFiles from "../Components/Essentials/ShowMdFiles";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PrintReceipt from "../Components/PrintReceipt/PrintReceipt";
import { getGoodSemester } from "../helper/GlobalConstants";
import CallToAction from "@material-ui/icons/CallToAction";

class FeePaymentContainer extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    email: "",
    type: "",
    current_course: "", // new
    current_semester: "", // new
    samarth_roll_no: "", // new
    registration_no: "", // new
    university_roll_no: "", // new university roll no
    dateOfBirth: "",
    data: [],
    isLoaded: false,
    isFeeStringLoaded: false,
    paymentData: [],
    fees_id: "",
    amount_to_be_paid: "",
    shift: "",
    showQr: false,
    qrLink: "",
    amount_paid: "0",
    transaction_id: "",
    date_of_payment: "",
    errorUpi: "",
  };

  onChange = (e) => {
    if (e.target.name == "fees_id") {
      let id = e.target.value;
      let amount_to_be_paid = 0;

      let d = this.state.data.paymentMasters.find((el) => el.id == id);
      if (d) {
        amount_to_be_paid = d.payment_amount;
      }
      this.setState({
        [e.target.name]: e.target.value,
        amount_to_be_paid: parseFloat(amount_to_be_paid).toFixed(2),
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onSubmit = () => {
    if (
      this.state.type != "" &&
      this.state.email != "" &&
      this.state.dateOfBirth != ""
    ) {
      // search the database for the user in student table and fetch the data along with the payment receipts of previous payment.
      PostData(`/findstudentforfeepayment`, {
        type: this.state.type,
        email: this.state.email,
        dateOfBirth: this.state.dateOfBirth,
      }).then((resp) => {
        console.log(
          "🚀 ~ file: FeePaymentContainer.js:45 ~ FeePaymentContainer ~ resp",
          resp
        );
        if (resp != false) {
          this.setState({
            data: resp,
            samarth_roll_no: resp.samarth_roll_no,
            registration_no: resp.registration_no,
            university_roll_no: resp.university_roll_no,
            current_course: resp.course,
            current_semester: resp.semester,
            isLoaded: true,
            isFeeStringLoaded: false,
            shift: resp.shift != null ? "" : resp.shift,
          });
        } else {
          this.setState({
            isLoaded: false,
            isFeeStringLoaded: false,
          });
          alert("No entry found");
        }
      });
    }
  };

  onFeeSubmit() {
    if (
      this.state.fees_id != "" &&
      this.state.amount_to_be_paid != "" &&
      this.state.shift != ""
    ) {
      // get the payment string and set up the payment method
      PostData(`/validatepayment`, {
        shift: this.state.shift,
        current_course: this.state.current_course,
      })
        .then((resp) => {
          if (resp && resp.status == true) {
            this.setState({
              showQr: true,
              qrLink: resp.data,
            });
          } else {
            alert(`You Cannot make payment now`);
            this.setState({
              showQr: false,
              qrLink: "",
            });
          }
        })
        .catch((err) => {
          this.setState({
            showQr: true,
            qrLink: "",
          });
        });
    }
  }

  validateUPITransactionNO(trid) {
    var reg = new RegExp("^[a-zA-Z0-9_]*$");
    var test = reg.test(trid);

    if (!test) {
      this.setState({
        errorUpi: "Please put the transaction id",
      });
    } else {
      this.setState({
        errorUpi: "",
      });
    }
    return test;
  }

  onSubmitFeeWithTransactionId() {
    if (
      this.state.fees_id != "" &&
      this.state.amount_to_be_paid != "" &&
      this.state.shift != "" &&
      this.state.amount_paid != "" &&
      this.state.transaction_id != "" &&
      this.state.email != "" &&
      this.state.dateOfBirth != "" &&
      this.state.date_of_payment != "" &&
      this.validateUPITransactionNO(this.state.transaction_id)
    ) {
      if (["BBA", "BCA", "BA", "BCOM"].includes(this.state.current_course)) {
        if (
          this.state.current_semester == 1 ||
          this.state.current_semester == 2
        ) {
          // samarth roll number is mandatory
          if (this.state.samarth_roll_no == "") {
            return false;
          }
        } else {
          if (
            this.state.university_roll_no == "" ||
            this.state.registration_no == ""
          ) {
            return false;
          }
        }
      }

      // update the receipt

      PostData(`/submitfeereceiptdata`, {
        student_id: this.state.data.id,
        transaction_id: this.state.transaction_id,
        amount_paid: this.state.amount_paid,
        fees_id: this.state.fees_id,
        date_of_payment: this.state.date_of_payment,
        shift: this.state.shift,
        samarth_roll_no: this.state.samarth_roll_no,
        registration_no: this.state.registration_no,
        type: this.state.type,
        university_roll_no: this.state.university_roll_no,
        current_course: this.state.current_course,
        current_semester: this.state.current_semester,
      }).then((resp) => {
        if (resp != false) {
          if (resp.status == true) {
            alert(resp.message);
            // redirect to print the same receipt

            window.open(
              `/print-receipt/${this.state.email}?receiptNo=${resp.receipt_no_new}`
            );
            window.location.reload();
          } else {
            alert(resp.message);
          }
        } else {
          alert("Failed to add receipt");
        }
      });
    }
  }

  render() {
    return (
      <div>
        <Navbar history={this.props.history} />

        <div className="cont">
          <Card>
            <CardHeader
              title="Enter Email Id to Pay the Fees"
              subheader="Enter email and Date of Birth to fetch the payment data"
            />
            <CardContent>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>Select Type of Fees Payment</div>
                  <select
                    className="form-control"
                    name="type"
                    value={this.state.type}
                    onChange={this.onChange}
                  >
                    <option value="">-- Select Fees Type --</option>
                    <option value="ADMISSION_FEE">Admission Fees</option>
                    <option value="REGISTRATION_FEES">Registration Fees</option>
                    <option value="EXAMINATION_FEE">Examination Fees</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>Your Email id (Registered with this portal)</div>
                  <input
                    className="form-control"
                    placeholder="email id"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>Date of Birth</div>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Date of Birth"
                    name="dateOfBirth"
                    value={this.state.dateOfBirth}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.onSubmit()}
                    style={{ marginTop: 18 }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <br />

          {this.state.isLoaded && (
            <Card>
              <CardHeader
                title="Details of the Fees to be paid"
                subheader="Verify the details of the Fees to be paid"
              />
              <CardContent>
                {(this.state.current_semester == 1 ||
                  this.state.current_semester == 2) &&
                  ["BBA", "BCA", "BA", "BCOM"].includes(
                    this.state.current_course
                  ) && (
                    <div>
                      <strong>Kindly fillup the following details</strong>{" "}
                      <br />
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div>
                            Samarth Roll No{" "}
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <input
                            className="form-control"
                            placeholder="Samarth Roll No"
                            onChange={this.onChange}
                            value={this.state.samarth_roll_no}
                            name="samarth_roll_no"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}

                {this.state.current_semester > 2 && (
                  <div>
                    <strong>Kindly fillup the following details</strong> <br />
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <div>
                          University Roll No{" "}
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <input
                          className="form-control"
                          placeholder="University Roll No"
                          onChange={this.onChange}
                          value={this.state.university_roll_no}
                          name="university_roll_no"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <div>
                          University Registration No{" "}
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <input
                          className="form-control"
                          placeholder="University Registration No"
                          onChange={this.onChange}
                          value={this.state.registration_no}
                          name="registration_no"
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}

                <ul>
                  {Array.isArray(this.state.data.feeDetails) &&
                    this.state.data.feeDetails.map((el, index) => (
                      <li>
                        {el.type} was paid on {el.date} of Rs. {el.amount_paid}.{" "}
                        <a
                          style={{ color: "blue" }}
                          target="_blank"
                          href={`/print-receipt/${this.state.email}?receiptNo=${el.receipt_no}`}
                        >
                          Print Receipt
                        </a>
                      </li>
                    ))}
                </ul>
                <br />
                <Grid container spacing={24}>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <div>Select Type of Fees Payment</div>
                    <select
                      className="form-control"
                      onChange={this.onChange}
                      value={this.state.fees_id}
                      name="fees_id"
                    >
                      <option value="">-- Select Fees Type --</option>
                      {Array.isArray(this.state.data.paymentMasters) &&
                        this.state.data.paymentMasters.map((el, index) => (
                          <option key={index} value={el.id}>
                            {el.payment_head} for {getGoodSemester(el.semester)}{" "}
                            Semester of Rs. {el.payment_amount}
                          </option>
                        ))}
                    </select>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <div>Amount to be paid</div>
                    <input
                      className="form-control"
                      placeholder="Amount"
                      onChange={this.onChange}
                      value={this.state.amount_to_be_paid}
                      name="amount_to_be_paid"
                      readOnly={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <div>Select Shift</div>
                    <select
                      className="form-control"
                      onChange={this.onChange}
                      value={this.state.shift}
                      name="shift"
                    >
                      <option value="">-- Select Shift --</option>
                      {["Day Shift", "Evening Shift"].map((el, index) => (
                        <option key={index} value={el}>
                          {el}
                        </option>
                      ))}
                    </select>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginTop: 18 }}
                      onClick={() => this.onFeeSubmit()}
                    >
                      Proceed to Payment
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}

          <br />

          {/* {this.state.isFeeStringLoaded && <Card>
                        <CardHeader title="Proceed to Payment" subheader="Click on the button below to visit the payment portal." />
                        <CardContent>

                            <form method="post" action={this.state.paymentData.action}>
                                <input type="hidden" name="encRequest" value={this.state.paymentData.encRequest} />
                                <input type="hidden" name="access_code" value={this.state.paymentData.access_code} />
                                <Button
                                    variant="contained"
                                    size="sm"
                                    color="primary"
                                    type="submit"
                                >
                                    <CallToAction /> &nbsp; Proceed To Pay &#8377; {this.state.paymentData.amount}
                                </Button>
                            </form>
                        </CardContent>
                    </Card>} */}

          {this.state.showQr && this.state.shift == "Day Shift" && (
            <>
              <Card>
                <CardHeader
                  title={`Scan the QR and pay Rs. ${this.state.amount_to_be_paid}. `}
                  //subheader="Insert the transaction details below"
                />
                <CardContent>
                  <img src={this.state.qrLink} style={{ width: "50%" }} />
                </CardContent>
              </Card>
            </>
          )}

          {this.state.showQr && this.state.shift == "Evening Shift" && (
            <>
              <Card>
                <CardHeader
                  title={`Scan the QR and pay Rs. ${this.state.amount_to_be_paid}. `}
                  // subheader="Insert the transaction details below"
                />
                <CardContent>
                  <img src={this.state.qrLink} style={{ width: "50%" }} />
                </CardContent>
              </Card>
            </>
          )}

          {this.state.showQr && (
            <>
              <Card>
                <CardHeader
                  title={`Insert Transaction Details`}
                  subheader="Insert the transaction details below"
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <div>Amount Paid</div>
                      <input
                        className="form-control"
                        placeholder="Amount Paid"
                        onChange={this.onChange}
                        value={this.state.amount_paid}
                        name="amount_paid"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <div>Transaction ID (as mentioned in the UPI app)</div>
                      <input
                        className="form-control"
                        placeholder="Transaction ID"
                        onChange={this.onChange}
                        value={this.state.transaction_id}
                        name="transaction_id"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <div>Date of Payment</div>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Date of Payment"
                        onChange={this.onChange}
                        value={this.state.date_of_payment}
                        name="date_of_payment"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 18 }}
                        onClick={() => this.onSubmitFeeWithTransactionId()}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          )}
        </div>

        <FooterDiv />
      </div>
    );
  }
}

export default FeePaymentContainer;

import React, { Component } from 'react'
import FormOne from './FormOne';
import { withStyles, Fade } from '@material-ui/core';
import { PostData, PostDataStb } from '../../api/service';
import FormTwo from './FormTwo';
import FormThree from './FormThree';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const styles = theme => ({

})

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: 1,
            email: '',
            date_of_birth: '',
            message: '',
            otp: '',
            error_one: '',
            type: 'RE_ADMISSION'
        }


    }

    changeForm = (id) => {
        this.setState({
            form: id
        })
    }

    setEmail(email,phone, date_of_birth, type) {

        const data = {
            email: email,
            phone: phone,
            date_of_birth: date_of_birth,
            type: type
        }


//
        if(type == 'NEW_ADMISSION'){
            PostData('/forgotpassworda', data)
            .then((resp) => {
                if(resp !== false){
                this.setState({
                    email: email,
                    phone:phone,
                    message: resp.message,
                    form: 2,
                    otp: resp.otp
                })
                }else{
                    this.setState({
                        error_one: 'No entry found with this email id'
                    })
                }
            })
        }else{
            PostData('/forgotpassword', data)
            .then((resp) => {
            
                if (resp.status !== false) {
                    this.setState({
                        email: email,
                        phone:phone,
                        message: resp.message,
                        form: 2,
                        otp: resp.otp
                    })
                    this.setOtp(resp.otp)
                } else {
                    this.setState({
                        error_one: resp.message
                    })
                }
            })
        }


       


    }

    setOtp(otp) {
        const data = {
            otp: otp,
            email: this.state.email,
            phone: this.state.phone,
            type: this.state.type
        }
        if(this.state.type == "NEW_ADMISSION"){
            PostData('/forgotpassworda/otp', data)
            .then((resp) => {
                if(resp === true){
                    this.setState({
                        form: 3,
                        message: '',
                        otp: otp
                    })
                }else{
                    this.setState({
                        message: 'Wrong OTP! Please try again.'
                    })
                }
            })
        }else{
        PostData('/forgotpassword/otp', data)
            .then((resp) => {
                if (resp === true) {
                    this.setState({
                        form: 3,
                        message: '',
                        otp: otp
                    })
                } else {
                    this.setState({
                        message: 'Wrong OTP! Please try again.'
                    })
                }
            })
        }
    }


    setPassword(password) {
        const data = {
            email: this.state.email,
            phone: this.state.phone,
            password: password,
            otp: this.state.otp,
            type: this.state.type
        }

        if(this.state.type == "NEW_ADMISSION"){
            PostData('/forgotpassworda/final', data)
            .then((resp) => {
                //console.log(resp)
                if(resp === true){
                    confirmAlert({
                        title: 'Successfully Updated',
                        message: 'You will be redirected to the login page',
                        buttons: [
                            {
                                label: 'OKAY',
                                onClick: () => this.props.history.replace('/')
                            }
                        ]
                    })
                }else{
                    confirmAlert({
                        title: 'Something Went Wrong',
                        message: 'Please try again later',
                        buttons: [
                            {
                                label: 'OKAY',
                                onClick: () => this.props.history.replace('/')
                            }
                        ]
                    })
                }
            })
        }else{
        PostData('/forgotpassword/final', data)
            .then((resp) => {
                //console.log(resp)
                if (resp === true) {
                    confirmAlert({
                        title: 'Successfully Updated',
                        message: 'You will be redirected to the login page',
                        buttons: [
                            {
                                label: 'OKAY',
                                onClick: () => this.props.history.replace('/')
                            }
                        ]
                    })
                } else {
                    confirmAlert({
                        title: 'Something Went Wrong',
                        message: 'Please try again later',
                        buttons: [
                            {
                                label: 'OKAY',
                                onClick: () => this.props.history.replace('/')
                            }
                        ]
                    })
                }
            })
        }
    }


    render() {
        switch (this.state.form) {
            case 1:
                return (
                    
                        <FormOne setEmail={this.setEmail.bind(this)} error={this.state.error_one} />
                    )

            case 2:
                return <FormTwo message={this.state.message} email={this.state.email} setOtp={this.setOtp.bind(this)} />

            case 3:
                return <FormThree setPassword={this.setPassword.bind(this)} />

            default:
                break;
        }
    }
}


export default withStyles(styles)(ForgotPassword)
import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './App.css';
import Login from './auth_components/js/Login';
import HomePage from './Containers/HomePage';
import SettingsContainer from './Containers/SettingsContainer';
import ForgotPasswordContainer from './Containers/ForgotPasswordContainer';
import RegistrationContainer from './Containers/RegistrationContainer';
import HomePageUser from './Containers/HomePageUser';
import FileListPage from './Containers/FileListPage';
import ReadmissionHomePage from './Containers/ReadmissionHomePage';
import ReRegistrationContainer from './Containers/ReRegistrationContainer';
import HomePageReUser from './Containers/HomePageReUser';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import HomePageUserHostel from './Containers/HomePageUserHostel';
import PrintReceipt from './Containers/PrintReceipt';
import FeePaymentContainer from './Containers/FeePaymentContainer';
import PaymentResponseAll from './Components/FeePaymentAll/PaymentResponseAll';
import FeePaymentArrearContainer from './Containers/FeePaymentArrearContainer';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  }
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
      <Router history={history} >
        <div>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/forgotpassword" component={ForgotPasswordContainer} />
            <Route exact path="/" render={(props) => <HomePage {...props} />} />
            <Route exact path="/feepayment" render={(props) => <FeePaymentContainer {...props} />} />
            <Route exact path="/feepayment-arrear" render={(props) => <FeePaymentArrearContainer {...props} />} />
            <Route exact path="/paymentresponseind" render={(props) => <PaymentResponseAll {...props} />} />
            <Route exact path="/print-receipt/:email" render={(props) => <PrintReceipt {...props} />} />
            {/* <Route exact path="/" render={(props) => <ReRegistrationContainer {...props} />} /> */}

            <Route exact path="/filelist" render={(props) => <FileListPage {...props} />} />
            <Route exact path="/registration" render={(props) => <RegistrationContainer {...props} />} />
            <Route exact path="/reregistration" render={(props) => <ReRegistrationContainer {...props} />} />
            <Route exact path="/home" render={(props) => <HomePageUser {...props} />} />
            <Route exact path="/admission" render={(props) => <HomePageUser {...props} />} />
            
            <Route exact path="/admission/ack" render={(props) => <HomePageUser {...props} />} />
            <Route exact path="/rehome" render={(props) => <HomePageReUser {...props} />} />
            <Route exact path="/final" render={(props) => <HomePageUser {...props} />} />
            <Route exact path="/refinal" render={(props) => <HomePageReUser {...props} />} />
            <Route exact path="/re-admission" render={(props) => <HomePageReUser {...props} />} />
            <Route exact path="/re-admission/ack" render={(props) => <HomePageReUser {...props} />} />
            <Route exact path="/re-admission-payment/:form_id" render={(props) => <HomePageReUser {...props} />} />
            <Route exact path="/examfeesre/:form_id" render={(props) => <HomePageReUser {...props} />} />
            <Route exact path="/admission-payment/:form_id" render={(props) => <HomePageUser {...props} />} />
            <Route exact path="/admission-ack" render={(props) => <HomePageReUser {...props} />} />
            <Route exact path="/paymentresponse" render={(props) => <HomePageUser {...props} />} />
            <Route exact path="/paymentresponse-admission" render={(props) => <HomePageUser {...props} />} />
            <Route exact path="/repaymentresponse" render={(props) => <HomePageReUser {...props} />} />
            <Route exact path="/readmission" render={(props) => <ReadmissionHomePage {...props} />} />
            <Route exact path="/settings" component={SettingsContainer} />


            <Route exact path="/hostel-admission" render={(props) => <HomePageUserHostel {...props} />} />
            <Route exact path="/hostel-home" render={(props) => <HomePageUserHostel {...props} />} />
            <Route exact path="/hostel-final" render={(props) => <HomePageUserHostel {...props} />} />
            <Route exact path="/hostel-final-admission" render={(props) => <HomePageUserHostel {...props} />} />
            <Route exact path="/hostel-final-payment/:receipt_no" render={(props) => <HomePageUserHostel {...props} />} />
            <Route exact path="/hostel-admission-ack" render={(props) => <HomePageUserHostel {...props} />} />
          </Switch>
        </div>
      </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;

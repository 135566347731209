import React, { Component } from "react";
import {
  withStyles,
  Button,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  CardActions,
  Typography,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { blue, orange, grey, green, red } from "@material-ui/core/colors";
import PreviewForm from "../View/PreviewForm";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CallToAction from "@material-ui/icons/CallToAction";
import { GetData, PostData } from "../../../api/service";
import AlertDiv from "./AlertDiv";
import Loading from "./Loading";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "60vh",
    alignItems: "center",
    justifyContent: "center",
  },
  redStrip: {
    width: "100%",
    height: 8,
    backgroundColor: blue[400],
  },
});

class ManualPaymentPage extends Component {
  state = {
    msg: "",
    link: "",
    amount: "",
    isLoaded: false,
    isPaymentLoaded: false,
    errors: [],
    payment_receipt: [],
    data: [],
    fees_group_id: "",
    is_free_admission: "",
    amount_paid: "",
    error: "",
    transaction_id: "",
    fees_id: "",
    date_of_payment: "",
    shift: "",
    fees_list: [],
    isFeesLoaded: false,
    showQr: false,
    qrLink: "",
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getInitialData = () => {
    GetData(
      `/${this.props.user.email}/admissionform/getactivestudentsingle`
    ).then((resp) => {
      if (Array.isArray(resp) && resp.length > 0) {
        this.setState({
          data: resp[0],
        });

        PostData(`/getlistoffeesforadmission`, {
          course: resp[0].course,
          stream: resp[0].stream,
          semester: resp[0].semester,
        }).then((resp1) => {
          this.setState({
            fees_list: resp1,
            isFeesLoaded: true,
          });
        });

        PostData(`/validatepayment`, {
          shift: resp[0].shift,
          current_course: resp[0].course,
        })
          .then((resp) => {
            if (resp && resp.status == true) {
              this.setState({
                showQr: true,
                qrLink: resp.data,
              });
            } else {
              alert(`You Cannot make payment now`);
              this.setState({
                showQr: false,
                qrLink: "",
              });
            }
          })
          .catch((err) => {
            this.setState({
              showQr: true,
              qrLink: "",
            });
          });
      }
    });
  };

  componentDidMount() {
    // get the payment data now

    this.getInitialData();
    //console.log(this.props)
    GetData(`/${this.props.user.email}/admissionform/haspaymentdone`).then(
      (resp) => {
        console.log(
          "🚀 ~ file: NewPaymentPageRe.js ~ line 74 ~ NewPaymentPageRe ~ .then ~ resp",
          resp
        );
        if (!resp) {
          this.setState({
            isLoaded: true,
          });
        } else {
          this.props.history.replace("/repaymentresponse");
        }
      }
    );
  }

  onChangePhoto = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  getFeeAmount = (fee_id) => {
    let fd = this.state.fees_list.find((el) => el.id == fee_id);
    if (fd) {
      return fd.payment_amount;
    } else {
      return "";
    }
  };

  handleValidation = () => {
    let {
      payment_receipt,
      fees_id,
      amount_paid,
      transaction_id,
      date_of_payment,
    } = this.state;
    let isValid = true;

    let errors = [];

    if (fees_id == "" || fees_id == null) {
      isValid = false;
      errors["fees_id"] = "Please fill up this field";
    } else {
      errors["fees_id"] = "";
    }

    if (parseFloat(this.getFeeAmount(fees_id)) > 0) {
      if (amount_paid == "" || amount_paid == null) {
        isValid = false;
        errors["amount_paid"] = "Please fill up this field";
      } else {
        errors["amount_paid"] = "";
      }

      if (transaction_id == "" || transaction_id == null) {
        isValid = false;
        errors["transaction_id"] = "Please fill up this field";
      } else {
        errors["transaction_id"] = "";
      }

      if (date_of_payment == "" || date_of_payment == null) {
        isValid = false;
        errors["date_of_payment"] = "Please fill up this field";
      } else {
        errors["date_of_payment"] = "";
      }

      if (!this.validateUPITransactionNO(transaction_id)) {
        isValid = false;
        errors["transaction_id"] = "Please insert correct transaction id";
      } else {
        errors["transaction_id"] = "";
      }
    }

    // if (Array.isArray(payment_receipt) && payment_receipt.length === 0) {
    //   isValid = false;
    //   errors["payment_receipt"] = "Please fill up this field";
    // } else {
    //   errors["payment_receipt"] = "";
    // }

    console.log(errors);

    this.setState({
      errors,
    });

    return isValid;
  };

  validateUPITransactionNO = (trid) => {
    var reg = new RegExp("^[a-zA-Z0-9_]*$");
    var test = reg.test(trid);

    if (!test) {
      this.setState({
        errorUpi: "Please put the transaction id",
      });
    } else {
      this.setState({
        errorUpi: "",
      });
    }
    return test;
  };

  onSubmit = () => {
    if (this.handleValidation()) {
      confirmAlert({
        title: "Final Submission",
        message:
          "After clicking the Final Submission button, you cannot change your form details.",
        buttons: [
          {
            label: "Final Submit",
            onClick: () => this.onSubmitFeeWithTransactionId(),
          },
          {
            label: "Previous page",
          },
        ],
      });
    }
  };

  finalSubmit = (d) => {
    PostData(`/admissionform/paymentdoing`, d)
      .then((resp) => {
        if (resp == true) {
          alert(`Successfully Submitted`);
        } else {
          alert(`Error in submitting`);
        }
        window.location.reload();
      })
      .catch((err) => {
        alert(`Error`);
      });
  };

  onSubmitFeeWithTransactionId = () => {
    // check if amount is zero
    // if zero then the function will be different
    // now the validation is done, so generate the receipt
    PostData(`/submitfeereceiptdata-re`, {
      form_id: this.state.data.form_id,
      transaction_id: this.state.transaction_id,
      amount_paid: this.state.amount_paid,
      fees_id: this.state.fees_id,
      date_of_payment: this.state.date_of_payment,
      applied_for_free:
        parseFloat(this.getFeeAmount(this.state.fees_id)) == 0 ? true : false,
    }).then((resp) => {
      alert(resp.message);
      if (resp.status == true) {
        window.location.reload();
      }
    });
  };

  render() {
    let { classes } = this.props;

    return (
      <div>
        {this.state.error != "" && (
          <AlertDiv border={red[300]} back={red[50]}>
            {this.state.error}
          </AlertDiv>
        )}

        {this.state.isLoaded && (
          <React.Fragment>
            <Card>
              <CardHeader title={`Fees Details`} subheader={`Declaration`} />
              <CardContent>
                {this.state.data.is_free_admission == 1 &&
                  this.state.data.has_good_attendance == 1 && (
                    <AlertDiv back={green[100]} border={green[400]}>
                      <Typography variant="subtitle2">
                        You can apply for free admission, you need to choose the
                        amount 0.00 from the dropdown below and click on submit.{" "}
                        <strong>
                          Remember: Your free admission is subjected to
                          verification of the income proof to be done by the
                          college authority.
                        </strong>
                      </Typography>
                    </AlertDiv>
                  )}

                <Grid container spacing={2}>
                  {this.state.is_free_admission != 1 ? (
                    <>
                      <Grid item xs={12} sm={12} lg={12} md={12}>
                        <Typography
                          variant="subtitle2"
                          style={{ fontWeight: 700 }}
                        >
                          Select Fees <span className="red-alert">*</span>
                        </Typography>
                        <select
                          className="form-control"
                          type="text"
                          name="fees_id"
                          required
                          value={this.state.fees_id}
                          onChange={this.onChange}
                        >
                          <option value="">-- Select --</option>
                          {Array.isArray(this.state.fees_list) &&
                            this.state.fees_list.map((el, index) => (
                              <option key={index} value={el.id}>
                                {el.payment_head} of Rs. {el.payment_amount}
                              </option>
                            ))}
                        </select>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                      <br />
                      <AlertDiv border={green[300]} back={green[50]}>
                        <strong>
                          As you have applied for Free Admission, the admission
                          amount is zero, click on "Submit" button generate the
                          receipt.
                        </strong>
                      </AlertDiv>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>

            {this.state.showQr &&
              this.state.fees_id != "" &&
              parseFloat(this.getFeeAmount(this.state.fees_id)) > 0 && (
                <>
                  <Card>
                    <CardHeader
                      title={`Scan the QR and pay Rs. ${this.getFeeAmount(
                        this.state.fees_id
                      )}. `}
                      subheader="Insert the transaction details after the payment"
                    />
                    <CardContent
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={this.state.qrLink}
                        style={{
                          width: "100%",
                          height: 600,
                          objectFit: "contain",
                        }}
                      />
                    </CardContent>
                  </Card>
                </>
              )}

            {this.state.showQr &&
              this.state.fees_id != "" &&
              parseFloat(this.getFeeAmount(this.state.fees_id)) > 0 && (
                <>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div>Amount Paid</div>
                          <input
                            className="form-control"
                            placeholder="Amount Paid"
                            onChange={this.onChange}
                            value={this.state.amount_paid}
                            name="amount_paid"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div>
                            Transaction ID (as mentioned in the UPI app)
                          </div>
                          <input
                            className="form-control"
                            placeholder="Transaction ID"
                            onChange={this.onChange}
                            value={this.state.transaction_id}
                            name="transaction_id"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div>Date of Payment</div>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date of Payment"
                            onChange={this.onChange}
                            value={this.state.date_of_payment}
                            name="date_of_payment"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              type="button"
                              variant="contained"
                              size="small"
                              style={{ marginTop: 18 }}
                              onClick={this.props.back}
                            >
                              Previous Step
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginTop: 18 }}
                              onClick={() => this.onSubmit()}
                            >
                              Submit
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </>
              )}

            {this.state.showQr &&
              this.state.fees_id != "" &&
              parseFloat(this.getFeeAmount(this.state.fees_id)) == 0 && (
                <>
                  <Card>
                    <CardContent>
                      <Button
                        type="button"
                        variant="contained"
                        size="small"
                        style={{ marginTop: 18 }}
                        onClick={this.props.back}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 18 }}
                        onClick={() => this.onSubmit()}
                      >
                        Submit
                      </Button>
                    </CardContent>
                  </Card>
                </>
              )}

            {Array.isArray(this.state.fees_list) &&
              this.state.fees_list.length == 0 && (
                <Card>
                  <CardContent>
                    <Button
                      type="button"
                      variant="contained"
                      size="small"
                      style={{ marginTop: 18 }}
                      onClick={this.props.back}
                    >
                      Previous Step
                    </Button>
                  </CardContent>
                </Card>
              )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ManualPaymentPage);

import { Button, Card, CardActions, CardContent, CardHeader } from '@material-ui/core'
import React, { Component } from 'react'

export default class PrintReceipt extends Component {

    state = {
        email: ""
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    onSubmit = () => {
        if(this.state.email != ""){

            // submit and fetch the data
            window.location.href=`/print-receipt/${this.state.email}`
        }
    }
    render() {
        return (
            <div>
                <Card raised>
                    <CardHeader
                        title="Print Admission Receipt"
                        subheader="Insert your email id here"
                    />

                    <CardContent>
                        <input
                            className='form-control'
                            placeholder='Enter your email id'
                            value={this.state.email}
                            name="email"
                            onChange={this.onChange}
                        />
                    </CardContent>
                    <CardActions style={{ justifyContent: 'flex-end' }}>
                        <Button variant='contained' onClick={() => this.onSubmit()} color="primary" style={{backgroundColor: '#2196F3', borderColor: '#2196F3'}}>Submit</Button>
                    </CardActions>
                </Card>
            </div>
        )
    }
}

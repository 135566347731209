import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles, Typography, Divider, Button } from '@material-ui/core';

import ReactToPrint from 'react-to-print';

import queryString, { parse } from 'query-string'
import { GetData } from '../../api/service';

const styles = theme => ({

})

class PaymentResponseAll extends Component {

    state = {
        data: [],
        data2:[],
        data2loaded:false,
        isLoaded: false,
        isL : false,
        msg: {
            status: "",
            message: ""
        }
    }

    componentDidMount() {


        let sr = this.props.history.location.search;
        if(sr != ""){
            const parsed = queryString.parse(sr);

            this.setState({
                msg: parsed
            })
        }

        

        GetData(`/getpaymentreceipt`)
            .then((resp) => {

                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp[0],
                        data2:resp.length == 2 ? resp[1]:[],
                        data2loaded:resp.length == 2 ? true:false,
                        isLoaded: true,
                        isL: false
                    })
                } else {
                   // this.props.history.replace('/home')
                   this.setState({
                       isL: false
                   })
                }
            })
    }


    render() {
        let { classes } = this.props;
        return (
            <div className='cont'>
             
                <h1>Payment Response</h1>


                {this.state.isL && "loading . . . "}

                {this.state.msg.status != "" && <div style={{display: 'flex', alignItems: 'center', backgroundColor: 'lightgrey', padding: 10, border: 'solid 1px black' }}>
                        <div style={{fontSize: 20, fontWeight: 700}}>{this.state.msg.status}</div>
                        <div style={{marginLeft: 10}}>{this.state.msg.message}</div>
                    </div>}


                {this.state.isLoaded && (
                    <div ref={el => (this.componentRef = el)}>
                    <Receipt
                        {...this.state.data}
                        
                        headerText={`Student's Copy`}
                        type={1}
                    />

                    
                    <div style={{pageBreakAfter: 'always'}} />

                    <br />
                    <br />
                    <br />

                    <Receipt
                        {...this.state.data}
                        
                        headerText={`Office Copy`}
                        type={1}
                    />

                    <div style={{marginTop: 20,}}><i>Office Copy to be submitted to the office.</i></div>
                    </div>
                )}


                <br />
                <br />

                {this.state.isLoaded ? (

                    <div>
                        <ReactToPrint
                            trigger={() =>
                                <Button
                                    variant="outlined"
                                    color="primary"

                                >
                                    Print Receipt
                        </Button>
                            }
                            ref={el => this.buttonRef = el}
                            content={() => this.componentRef}
                            pageStyle={{ margin: '10px' }}
                            copyStyles={true}
                        />

                        {this.props.user.role == 'STUDENT' &&
                                <Link to="/final">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                >
                                    Print Application Form 
                                </Button>
                            </Link>}
                            {this.props.user.role == 'RESTUDENT' &&
                                <Link to="/refinal">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                >
                                    Print Application Form 
                                </Button>
                            </Link>}
                    </div>
                ) : null}
                    <br />

                    {this.state.data2loaded && (
                    <div ref={el => (this.componentRef2 = el)}>
                    <Receipt
                        {...this.state.data2}
                        
                        headerText={`Student's Copy`}
                        type={2}
                    />

                    
                    <div style={{pageBreakAfter: 'always'}} />

                    <br />
                    <br />
                    <br />

                    <Receipt
                        {...this.state.data2}
                        
                        headerText={`Office Copy`}
                        type={2}

                    />

                    <div style={{marginTop: 20,}}><i>Office Copy to be submitted to the office.</i></div>
                    </div>
                )}


                <br />
                <br />

                {this.state.data2loaded ? (

                    <div>
                        <ReactToPrint
                            trigger={() =>
                                <Button
                                    variant="outlined"
                                    color="primary"

                                >
                                    Print Receipt
                        </Button>
                            }
                            ref={el => this.buttonRef = el}
                            content={() => this.componentRef2}
                            pageStyle={{ margin: '10px' }}
                            copyStyles={true}
                        />

                        {this.props.user.role == 'STUDENT' &&
                                <Link to="/final">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                >
                                    Print Application Form 
                                </Button>
                            </Link>}
                            {this.props.user.role == 'RESTUDENT' &&
                                <Link to="/refinal">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                >
                                    Print Application Form 
                                </Button>
                            </Link>}
                    </div>
                ) : null}

                
            </div>
        )
    }
}

export default withStyles(styles)(PaymentResponseAll);

class Receipt extends Component {


    goodSemester = (s) => {
        if(s == 1){
            return `1st Semester`
        }
        if(s == 2){
            return `2nd Semester`
        }

        if(s == 3){
            return `3rd Semester`
        }

        if(s >= 4){
            return `${s}th Semester`
        }
    }

    render() {
        return (
            <div>
                <table width="100%">
                    <tbody>
                        <tr><td style={{textAlign: 'center', fontWeight: 700, fontSize: 20}} colSpan={3}>{this.props.headerText}</td></tr>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={'/images/logo.jpg'}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    Dispur College
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Ganeshguri, Guwahati, Assam, India, Pin 781006
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Assam
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    Payment Receipt
                                </Typography>
                            </td>

                            <td width="20%" align="center">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Form No. : <b>{this.props.form_id}</b> <br />


                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <table className="table">

                    <tbody>
                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Name
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.name}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Father's Name
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.father_name}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Class
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.course} {this.props.stream} {this.goodSemester(this.props.semester)}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Form No
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.form_id}
                                </Typography>
                            </td>
                        </tr>


                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Payment Order No
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.type == 1 && this.props.transaction != null && this.props.transaction.order_id}
                                    {this.props.type == 2 && this.props.exam_fees_transaction != null && this.props.exam_fees_transaction.order_id}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Transaction Status
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    Success
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Receipt No
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.receipt_no}
                                </Typography>
                            </td>
                        </tr>


                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Tracking ID
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.type == 1 && this.props.transaction != null && this.props.transaction.tracking_id}
                                    {this.props.type == 2 && this.props.exam_fees_transaction != null && this.props.exam_fees_transaction.tracking_id}
                                    {this.props.transaction == null && "-"}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Bank Ref No.
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.type == 1 && this.props.transaction != null && this.props.transaction.bank_ref_no}
                                    {this.props.type == 1 && this.props.transaction == null && this.props.transaction_id}

                                    {this.props.type == 2 && this.props.exam_fees_transaction != null && this.props.exam_fees_transaction.bank_ref_no}
                                    {this.props.type == 2 && this.props.exam_fees_transaction == null && this.props.transaction_id}
                                </Typography>
                            </td>
                        </tr>


                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Date of Payment
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.type == 1 && this.props.transaction != null && this.props.transaction.trans_date}
                                    {this.props.type == 1 && this.props.transaction == null && this.props.date_of_admission}
                                    {this.props.type == 2 && this.props.exam_fees_transaction != null && this.props.exam_fees_transaction.trans_date}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Amount
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.amount_paid}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Mode of Payment
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.type ==1 && this.props.transaction != null && this.props.transaction.payment_mode}
                                    {this.props.type ==1  && this.props.transaction == null && `OFFLINE`}

                                    {this.props.type ==2 && this.props.exam_fees_transaction != null && this.props.exam_fees_transaction.payment_mode}
                                   
                                </Typography>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        )
    }

}
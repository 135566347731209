import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AuthService from '../auth_components/js/AuthService';
import { Menu, MenuItem, Badge, Avatar, Slide } from '@material-ui/core';
import FilterList from '@material-ui/icons/FilterList';
import { blue, grey } from '@material-ui/core/colors';
import MenuIcon from '@material-ui/icons/Menu'
import Close from '@material-ui/icons/Close'

const drawerWidth = 0;

const styles = theme => ({
    root: {

    },
    topBar: {
        height: 80,
        backgroundColor: grey[100],
        paddingLeft: '10vw',
        paddingRight: '10vw',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '2vw',
            paddingRight: '2vw'
        }
    },
    container: {
        display: 'flex',
        height: 80,
        alignItems: 'center',
    },
    topBarNavigations: {
        height: 40,
        backgroundColor: '#1715DA',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '10vw',
        paddingRight: '10vw',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end'
        }
    },
    navUl: {
        listStyleType: 'none',
        marginLeft: '-30px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    navLi: {
        display: 'inline-block',
        paddingLeft: '20px',
        paddingRight: '20px',
        cursor: 'pointer',
        color: 'white',
        fontSize: '0.8em',
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
        flexBasis: '100%',
        textAlign: 'right'
    },
    sectionMobile: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    grow: {
        flexGrow: 1,
    },
    toolbarClass: {
        color: blue[900],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'left'
    },
    profileButton: {
        borderRadius: '0',

    },
    ava: {
        width: 30,
        height: 30
    },
    notificationsIcon: {
        width: 30,
        height: 30
    },
    logoDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    logoImage: {
        paddingRight: '10px',
        width: '20%',
        [theme.breakpoints.down('sm')]: {
            width: '20%'
        }
    },
    textCollege: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9em'
        }
    },
    logoText: {

    },
    mobileMenuListDiv: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '60vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 12,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    smallMenuHolder: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridColumnGap: '20px'
    },
    itemSetup: {
        padding: '20px',
        color: 'white',
        textAlign: 'left'
    },
    mobileBars: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    }
});



class LoggedNavBar extends React.Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.onLogout = this.onLogout.bind(this);

    }

    state = {
        mobileOpen: false,
        anchorEl: null,
        mobileMoreAnchorEl: null,
        count_noti: 0,

    };

    getInitialCount() {

    }

    onLogout() {
        this.Auth.logout();
        this.props.history.replace('/');
    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    mobileMenuOpen = () => {
        this.setState({
            mobileMenu: !this.state.mobileMenu
        })
    }

    render() {
        const { classes, theme } = this.props;
        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem button><Link to="/settings">Change Password</Link></MenuItem>
                <MenuItem onClick={this.onLogout}>Logout</MenuItem>
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                <MenuItem button>
                    <Link to="/settings">
                        <p>Change Password</p>
                    </Link>
                </MenuItem>

                <MenuItem onClick={this.onLogout}>
                    <IconButton color="inherit">
                        <FilterList />
                    </IconButton>
                    <p>Logout</p>
                </MenuItem>
            </Menu>
        );

        const navigationArray = [
            {
                title: 'Home',
                path: this.props.type === 1 ?  `/home` : `/rehome`
            },
            // {
            //     title: 'Hostel-Admission',
            //     path: '/hostel-admission'
            // }
        ]
        return (
            <div className={classes.root}>
                <CssBaseline />
                <div className={classes.appBar}>
                    <div className={classes.topBar}>
                        <div className={classes.container}>
                            <Link to="/">
                                <div className={classes.logoDiv}>
                                    <img
                                        src={'/images/logo.jpg'}

                                        className={classes.logoImage}
                                    />
                                    <div className={classes.logoText}>
                                        <Typography
                                            variant="h6"
                                            className={classes.textCollege}
                                        >
                                             Dispur College
                                    </Typography>
                                        <Typography
                                            variant="subtitle2"
                                        >
                                           
                                            Admission Portal
                                    </Typography>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className={classes.topBarNavigations}>
                        <ul className={classes.navUl}>
                            {navigationArray.map((el, index) =>

                                <li className={classes.navLi} key={index}><Link to={el.path}>{el.title}</Link></li>

                            )}

                            {/* <li className={classes.navLi} key={12} >
                                <a
                                    href={'/pdfs/admission_procedure.pdf'}
                                    target="_blank"
                                    rel="noreferrer noopenner"
                                >
                                    Admission Procedure
                                </a>
                            </li>

                            <li className={classes.navLi} key={12} >
                                <a
                                    href={'/pdfs/admission_notice.pdf'}
                                    target="_blank"
                                    rel="noreferrer noopenner"
                                >
                                    Admission Notice
                                </a>
                            </li> */}
                            {/* <li className={classes.navLi} key={12} >
                                <a
                                    href={'/pdfs/prospectus_compressed.pdf'}
                                    target="_blank"
                                    rel="noreferrer noopenner"
                                >
                                    College Prospectus
                                </a>
                            </li> */}

                            <li className={classes.navLi} key={11} onClick={this.onLogout}>Logout</li>
                        </ul>

                        <div className={classes.mobileBars}>
                            {this.state.mobileMenu ?
                                <Close style={{ color: 'white' }} onClick={this.mobileMenuOpen} />
                                : <MenuIcon style={{ color: 'white' }} onClick={this.mobileMenuOpen} />}
                        </div>
                    </div>
                </div>

                <MobileMenu
                    {...this.props}
                    menuItems={navigationArray}
                    open={this.state.mobileMenu}
                    logOut={this.onLogout}
                />

            </div>
        );
    }
}

LoggedNavBar.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LoggedNavBar);

export const MobileMenu = (props) => {
    let { classes } = props;
    return (
        <Slide in={props.open} direction="right" mountOnEnter unmountOnExit>
            <div className={classes.mobileMenuListDiv}>
                <div className={classes.smallMenuHolder}>
                    {props.menuItems.map((el, index) =>
                        <Link key={index} to={el.path}>
                            <div key={index} className={classes.itemSetup}>

                                <Typography
                                    variant="button"
                                    color="inherit"
                                >
                                    {el.title}
                                </Typography>
                            </div>
                        </Link>

                    )}

                    {/* <a
                        href={'/pdfs/admission_procedure.pdf'}
                        target="_blank"
                        rel="noreferrer noopenner"
                    >
                        <div className={classes.itemSetup}>

                            <Typography
                                variant="button"
                                color="inherit"
                            >
                                Admission Procedure
                            </Typography>
                        </div>

                    </a>

                    <a
                        href={'/pdfs/admission_notice.pdf'}
                        target="_blank"
                        rel="noreferrer noopenner"
                    >
                        <div className={classes.itemSetup}>

                            <Typography
                                variant="button"
                                color="inherit"
                            >
                                Admission Notice
                            </Typography>
                        </div>

                    </a> */}


{/* <a
                        href={'/pdfs/prospectus_compressed.pdf'}
                        target="_blank"
                        rel="noreferrer noopenner"
                    >
                        <div className={classes.itemSetup}>

                            <Typography
                                variant="button"
                                color="inherit"
                            >
                               College Pospectus
                            </Typography>
                        </div>

                    </a> */}

                    <a
                        onClick={props.logOut}
                    >
                        <div className={classes.itemSetup}>

                            <Typography
                                variant="button"
                                color="inherit"
                            >
                                Logout
                            </Typography>
                        </div>

                    </a>

                </div>
            </div>
        </Slide>
    )
}
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles, Typography, Divider, Button } from '@material-ui/core';
import ReactToPrint from 'react-to-print';

import { PostData } from '../../api/service';
import Loading from '../Registration/Forms/Loading';

const styles = theme => ({

})

class PrintReceiptByEmail extends Component {

    state = {
        data: [],
        isLoaded: false
    }

    componentRef = []

    componentDidMount() {
        PostData(`/getpaymentreceiptadmissionbyemail`, {
            email: this.props.email,
            receipt_no: this.props.receiptNo
        })
            .then((resp) => {
            

                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp,
                        isLoaded: true
                    })
                } 
            })
    }


    render() {
        let { classes } = this.props;
        return (
            <div style={{padding: 15}}>
                
                <h1>Payment Response</h1>





                {this.state.isLoaded ? (
                   <div>
                    {this.state.data.map((el,index) =>  <div key={index} style={{marginBottom: 20}}>
                        <ReactToPrint
                            trigger={() =>
                                <Button
                                    variant="outlined"
                                    color="primary"

                                >
                                    Print Receipt
                        </Button>
                            }
                            ref={el => this.buttonRef = el}
                            content={() => this.componentRef[index]}
                            pageStyle={{ margin: '10px' }}
                            copyStyles={true}
                        />
                        <br />
                        <br />
                        
                        <Receipt
                        {...el}
                        ref={el1 => (this.componentRef[index] = el1)}
                    /></div>)}
                    
                   </div>
                ) : <Loading />}


             


            </div>
        )
    }
}

export default withStyles(styles)(PrintReceiptByEmail);

class Receipt extends Component {


    goodSemester = (sem) => {
        if(sem == 1){
            return `1st Semester`
        }
        if(sem == 2){
            return `2nd Semester`
        }

        if(sem == 3){
            return `3rd Semester`
        }

        if(sem >= 4){
            return `${sem}th Semester`
        }
    }

    render() {
        return (
            <div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={'/images/logo.jpg'}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    Dispur College
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Ganeshguri, Guwahati, Assam, India, Pin 781006
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Assam
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    Payment Receipt
                                </Typography>
                            </td>

                            <td width="20%" align="center">
                                {(this.props.form_id != "" && this.props.form_id != null) && <Typography
                                    variant="subtitle2"
                                >
                                    Form No. : <b>{this.props.form_id}</b> <br />


                                </Typography>}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <table className="table">

                    <tbody>
                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Name
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.name}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Father's Name
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.father_name}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Class
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.course} {this.props.stream} {this.goodSemester(this.props.payment_semester != null ? this.props.payment_semester : this.props.semester)}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Form No
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.form_id}
                                </Typography>
                            </td>
                        </tr>
                        {this.props.samarth_roll_no && <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                   Samarth Roll No
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.samarth_roll_no}
                                </Typography>
                            </td>
                        </tr>}

                        {this.props.university_roll_no && <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                   University Roll No
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.university_roll_no}
                                </Typography>
                            </td>
                        </tr>}

                        {this.props.registration_no && <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                   Registration No
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.registration_no}
                                </Typography>
                            </td>
                        </tr>}


                        {/* <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Payment Order No
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.transaction != null && this.props.transaction.order_id}
                                </Typography>
                            </td>
                        </tr> */}

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Transaction Status
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    Success
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Receipt No
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.receipt_no}  <span style={{color: "red"}}>{this.props.is_approved == 0 && `(This Payment is yet to be approved by college.Kindly Visit College Office for necessary action.)`}</span>
                                </Typography>
                            </td>
                        </tr>


                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Tracking ID
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                          
                                    {this.props.transaction_id}
                                </Typography>
                            </td>
                        </tr>

                        {/* <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Bank Ref No.
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.transaction != null && this.props.transaction.bank_ref_no}
                                    {this.props.transaction == null && this.props.transaction_id}
                                </Typography>
                            </td>
                        </tr> */}


                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Date of Payment
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                   
                                    {this.props.date}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Amount
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.amount_paid}
                                </Typography>
                            </td>
                        </tr>

                        {this.props.is_other == 1 && <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Remarks
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                       
                                   Arrear Batch {this.props.remarks}
                                </Typography>
                            </td>
                        </tr>}
                    </tbody>

                </table>

                <br />

                {this.props.is_approved == 0 && <div style={{color: 'red', fontSize: 12, fontWeight: 700}}>This Payment is yet to be approved by college.Kindly Visit College Office for necessary action.</div>}
            </div>
        )
    }
    
}
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles, Typography, Divider, Button, Grid } from '@material-ui/core';

import ReactToPrint from 'react-to-print';
import Loading from '../Forms/Loading';

const styles = theme => ({

})

class PaymentReceipt extends Component {

    state = {
        data: this.props.data,
        isLoaded: true
    }

    componentDidMount() {

    }


    render() {
        let { classes } = this.props;
        return (
            <div>
                <h1>Payment Receipt</h1>

                {this.state.isLoaded ? (
                    <Receipt
                        {...this.state.data}
                        ref={el => (this.componentRef = el)}
                    />
                ) : <Loading />}


                <br />
                <br />

                {this.state.isLoaded ? (

                    <div>
                        <ReactToPrint
                            trigger={() =>
                                <Button
                                    variant="outlined"
                                    color="primary"

                                >
                                    Print Payment Receipt
                        </Button>
                            }
                            ref={el => this.buttonRef = el}
                            content={() => this.componentRef}
                            pageStyle={{ margin: '10px' }}
                            copyStyles={true}
                        />


                    </div>
                ) : null}
            </div>
        )
    }
}

export default withStyles(styles)(PaymentReceipt);

class Receipt extends Component {
    goodSemester = (sem) => {
        if (sem == 1) {
            return '1st';
        } else if (sem == 2) {
            return '2nd';
        } else if (sem == 3) {
            return '3rd';
        } else {
            return `${sem}th`;
        }
    }
    render() {
        return (
            <div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={'/images/logo.jpg'}
                                    style={{ width: '100px' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    Dispur College
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Ganeshguri, Guwahati, Assam, India, Pin 781006
                                </Typography>

                                <Typography
                                    variant="body1"
                                >
                                    Payment Receipt
                                </Typography>
                            </td>

                            <td width="20%" align="right">

                                <Typography
                                    variant="subtitle2"
                                >
                                    Receipt No. : <b>{this.props.receipt_no}</b> <br />
                                    Date : <b>{this.props.date}</b> <br />


                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />


                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography variant="body2">
                            Name: {this.props.name}
                        </Typography>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                            variant="body2"
                        >
                            Class:  {this.props.course}-{this.props.stream}- {this.goodSemester(this.props.semester)} {this.props.course === 'HS' ? `Year` : `Semester`} {this.props.course === 'TDC' ? ' - ' + this.props.course_type : null}
                        </Typography>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography variant="body2">
                            Roll No: {this.props.roll_no}
                        </Typography>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                            variant="body2"
                        >
                            Form No:  {this.props.form_id}
                        </Typography>
                    </Grid>

                   

                   
                </Grid>

                <br />



                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="80%">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>Particulars</td>
                                            <td>Amount (Rs.)</td>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {Array.isArray(this.props.fees) && this.props.fees.map((el, index) =>
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{el.sm_head}</td>
                                                <td style={{ textAlign: 'right' }}>{el.sm_amount}</td>
                                            </tr>
                                        )}

<tr>
                                            <td colSpan={3}>&nbsp;</td>
                                        </tr>

                                        <tr>
                                            <td colSpan={3}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>&nbsp;</td>
                                        </tr>

                                        

                                        <tr>
                                            <td style={{ textAlign: 'right' }} colSpan={2}>
                                                <b>Total</b>
                                            </td>
                                            <td style={{ textAlign: 'right' }}>{this.props.amount_paid}</td>
                                        </tr>
                                    </tbody>
                                   

                                </table>
                            </td>

                        </tr>
                    </tbody>
                </table>

                <br />





                <Typography variant="body2" style={{ textAlign: 'center' }}>
                    <strong>Note</strong> : Since this is a system generated Receipt, Signature is not required.
                </Typography>



            </div>
        )
    }

}


import * as React from 'react'
import { makeStyles, withStyles } from '@material-ui/core'
import marked from "marked"
import Skeleton from 'react-loading-skeleton';



const styles = theme => ({
    root: {
        width: '100%',
        textAlign: 'left',
        height: '100vh',
        overflowY: 'auto',
        fontFamily: 'Source Sans Pro'

    },
    di: {
        padding: theme.spacing.unit*3,
        
    }
})



class ShowMdFiles extends React.Component {

    state = {
        text: ''
    }
    componentDidMount() {
        const readmePath = `/MDx/${this.props.fileName}.md`;

        fetch(readmePath)
            .then(response => {
                return response.text()
            })
            .then(text => {
                this.setState({
                    text: marked(text)
                });
            })
    }

    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>

                {this.state.text != '' ? <div dangerouslySetInnerHTML={{ __html: this.state.text }} className={`new-info`} ></div> :
                    <div className={classes.di} >
                        <Skeleton count={10} />
                    </div>}
            </div>
        )
    }


}


export default withStyles(styles)(ShowMdFiles)
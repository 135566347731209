import React, { Component } from 'react'
import PrintReceiptByEmail from '../Components/PrintReceipt/PrintReceiptByEmail'
import queryString from 'query-string'

export default class PrintReceipt extends Component {

    state = {
        email: "",
        receiptNo: ""
    }

    componentDidMount(){
        

        let search = queryString.parse(this.props.history.location.search)
        this.setState({
          email: this.props.match.params.email,
          receiptNo: search.receiptNo
      })
        
    }

  render() {
    return (
      <div>


        {this.state.email != "" && <PrintReceiptByEmail
            email={this.state.email}
            receiptNo={this.state.receiptNo}
        />}


      </div>
    )
  }
}

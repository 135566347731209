import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles, Typography, Divider, Button } from '@material-ui/core';
import { GetData } from '../../../api/service';
import ReactToPrint from 'react-to-print';
import Loading from '../Forms/Loading';

const styles = theme => ({

})

class PaymentResponse extends Component {

    state = {
        data: [],
        isLoaded: false
    }

    componentDidMount() {
        GetData(`/getpaymentreceipt`)
            .then((resp) => {

                
                    this.setState({
                        data: resp,
                        isLoaded: true
                    })
                
            })
    }


    render() {
        let { classes } = this.props;
        return (
            <div>
                <h1>Payment Response</h1>





                {this.state.isLoaded ? (
                    <div ref={el => (this.componentRef = el)}>
                    {Array.isArray(this.state.data) && this.state.data.map((el,index) => <div key={index} style={{marginBottom: 10}}>
                        <Receipt
                        {...el}
                        
                    />
                    </div>)}</div>
                ) : <Loading />}


                <br />
                <br />

                {this.state.isLoaded ? (

                    <div>
                        <ReactToPrint
                            trigger={() =>
                                <Button
                                    variant="outlined"
                                    color="primary"

                                >
                                    Print Receipt
                        </Button>
                            }
                            ref={el => this.buttonRef = el}
                            content={() => this.componentRef}
                            pageStyle={{ margin: '10px' }}
                            copyStyles={true}
                        />

                        <Link to="/final">
                            <Button
                                variant="outlined"
                                color="primary"
                            >
                                Print Application Form
                            </Button>
                        </Link>
                    </div>
                ) : null}
            </div>
        )
    }
}

export default withStyles(styles)(PaymentResponse);

class Receipt extends Component {

    render() {
        return (
            <div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={'/images/logo.jpg'}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    Dispur College
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Ganeshguri, Guwahati, Assam, India, Pin 781006
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Assam
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    Payment Receipt
                                </Typography>
                            </td>

                            <td width="20%" align="center">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Form No. : <b>{this.props.form_id}</b> <br />


                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <table className="table">

                    <tbody>
                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Name
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.name}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Father's Name
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.father_name}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Class
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.course} {this.props.stream} 1st Semester
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Form No
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.form_id}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Roll No
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.roll_no}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Shift
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.shift}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Payment Order No
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.mop == 'ONLINE' ? this.props.transaction != null && this.props.transaction.order_id:'-'}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Transaction Status
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    Success
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Receipt No
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.receipt_no}
                                </Typography>
                            </td>
                        </tr>


                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Tracking ID
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.mop == 'ONLINE' ? this.props.transaction != null && this.props.transaction.tracking_id : '-'}
                                    {this.props.mop == 'ONLINE' ? this.props.transaction == null && "-" : '-'}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Bank Ref No.
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.mop == 'ONLINE' ? this.props.transaction != null && this.props.transaction.bank_ref_no : ''}
                                    {this.props.mop == 'ONLINE' ? this.props.transaction == null && this.props.transaction_id : '-'}
                                </Typography>
                            </td>
                        </tr>


                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Date of Payment
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    
                                    {this.props.date}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Amount
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.amount_paid}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Mode of Payment
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.mop == 'ONLINE' ? this.props.transaction != null && this.props.transaction.payment_mode : 'OFFLINE'}
                                    
                                </Typography>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        )
    }

}
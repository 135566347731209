import React, { Component } from 'react'
import { withStyles, Button, Typography, Grid, Divider, CircularProgress, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import { GetData } from '../../../api/service';
import './formprint.css'
import AlertDiv from '../Forms/AlertDiv';
import { grey } from '@material-ui/core/colors';
import Loading from '../Forms/Loading';


const styles = theme => ({

})

class PreviewForm extends Component {

    state = {
        data: [],
        isLoaded: false
    }

    getInitialData = () => {
        GetData(`/${this.props.user.email}/admissionform/getactivestudentsingle`)
            .then((resp) => {

                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp[0],
                        isLoaded: true
                    })
                }
            })
    }

    componentDidMount() {
        this.getInitialData();
    }

    render() {
        let { classes } = this.props;
        return (
            <div>


                {this.state.isLoaded ? <AdmissionForm
                    {...this.state.data}
                /> :
                    <Loading />
                }


            </div>
        )
    }
}

export default withStyles(styles)(PreviewForm);

class AdmissionForm extends Component {
    render() {
        let total_marks = 0;
        let marks_secured = 0;
        return (
            <div className="admission-form">



                <Grid container spacing={24}>

                    <Grid item lg={9} md={9} sm={9} xs={9}>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td width="40%">
                                        <Typography
                                            style={{
                                                fontWeight: '18px',
                                                fontWeight: 500
                                            }}
                                        >
                                            Class to which admission is sougth
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            :
                                        </Typography>
                                    </td>
                                    <td colSpan={`2`}>
                                        <Typography
                                            variant="body2"
                                        >
                                            {this.props.course == "TDC" ? "FYUGP" : this.props.course}-{this.props.stream}-1st {this.props.course === 'HS' ? `Year` : `Semester`}
                                        </Typography>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="40%">
                                        <Typography
                                            style={{
                                                fontWeight: '18px',
                                                fontWeight: 500
                                            }}
                                        >
                                            Student's Unique ID
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            :
                                        </Typography>
                                    </td>
                                    <td colSpan={`2`}>
                                        <Typography
                                            variant="body2"
                                        >
                                            {this.props.unique_id}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="40%">
                                        <Typography
                                            style={{
                                                fontWeight: '18px',
                                                fontWeight: 500
                                            }}
                                        >
                                            ARN No
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            :
                                        </Typography>
                                    </td>
                                    <td colSpan={`2`}>
                                        <Typography
                                            variant="body2"
                                        >
                                            {this.props.arn_no}
                                        </Typography>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="40%">
                                        <Typography
                                            style={{
                                                fontWeight: '18px',
                                                fontWeight: 500
                                            }}
                                        >
                                            Aadhar Number
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography>
                                            :
                                        </Typography>
                                    </td>
                                    <td colSpan={`2`}>
                                        <Typography
                                            variant="body2"
                                        >
                                            {this.props.aadhar_number}
                                        </Typography>
                                    </td>
                                </tr>

                                <tr>
                                    <td width="40%" colSpan={`4`}>
                                        <Typography
                                            style={{
                                                fontWeight: '18px',
                                                fontWeight: 500
                                            }}
                                        >
                                            Marks obtained in last examination
                                        </Typography>
                                    </td>

                                </tr>
                                <tr vAlign="bottom">
                                    <td colSpan="2">
                                        <table width="100%" className="table">
                                            <tbody>
                                                <tr>
                                                    <td width="50%">
                                                        <Typography>
                                                            Total Marks
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography variant="body2">
                                                            {this.props.total_marks}
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="50%">
                                                        <Typography>
                                                            Marks Obtained
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography variant="body2">
                                                            {this.props.marks_obtained}
                                                        </Typography>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>

                                    <td colSpan="2">
                                        <table width="100%" className="table">
                                            <tbody>

                                                <tr>
                                                    <td width="50%">
                                                        <Typography>
                                                            Percentage
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography variant="body2">
                                                            {this.props.percentage}
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="30%">
                                                        <Typography>
                                                            Do you need hostel accomodation
                                                        </Typography>
                                                    </td>
                                                    <td width="70%">
                                                        <Typography
                                                            variant="body2"
                                                        >
                                                            {this.props.hostel}
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4}>
                                        {this.props.course === 'HS' ? (

                                            <table width="100%" className="table">

                                                <tbody>
                                                    <tr>
                                                        <td colSpan="2">
                                                            <Typography style={{
                                                                fontWeight: '18px',
                                                                fontWeight: 500
                                                            }}>
                                                                Subjects opted for
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Typography
                                                                variant="body2"
                                                            >
                                                                Compulsory: {this.props.compulsory_1.label}, {this.props.compulsory_2.label}
                                                            </Typography>
                                                        </td>

                                                        <td>
                                                            <Typography
                                                                variant="body2"
                                                            >
                                                                MIL: {this.props.mil.label}
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2">
                                                            <Grid container>
                                                                {this.props.optional_subjects.map((el, index) => {
                                                                    if (el != null) {
                                                                        return (<Grid item lg={6} md={6} sm={6} xs={6} key={index}>
                                                                            <Typography variant="body2" >
                                                                                {index + 1} : {el.label}
                                                                            </Typography>
                                                                        </Grid>)
                                                                    }
                                                                }

                                                                )}
                                                            </Grid>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>) : null}

                                        {this.props.course === 'TDC' && this.props.stream == "ARTS" ? (<table width="100%" className="table">

                                            <tbody>
                                                <tr>
                                                    <td colSpan="4">
                                                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                            Combination of Subject sougth
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Typography variant="body2" style={{ fontWeight: 700 }} gutterBottom>
                                                            Core Course
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            Major Core: {this.props.major_1.label}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Minor 1: {this.props.major_2.label}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Minor 2: {this.props.major_3.label}
                                                        </Typography>
                                                    </td>


                                                    <td style={{ verticalAlign: 'top' }}>
                                                        {/* <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                            Multi-Disciplinary Course
                                                        </Typography>


                                                        <Typography variant="body2">
                                                            {this.props.generic_1.label}
                                                        </Typography> */}

                                                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                            Ability Enhancement Course
                                                        </Typography>


                                                        <Typography variant="body2">
                                                            {this.props.mil.label}
                                                        </Typography>
                                                        <br />

                                                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                            Value Added Course
                                                        </Typography>


                                                        <Typography variant="body2">
                                                            {this.props.generic_2.label}
                                                        </Typography>

                                                    </td>


                                                    {/* <td style={{ verticalAlign: 'top' }}>
                                                       
                                                        
                                                    </td>

                                                   */}
                                                </tr>
                                            </tbody>

                                        </table>) : null}
                                        {this.props.course === 'TDC' && this.props.stream == "COMMERCE" ? (<table width="100%" className="table">

                                            <tbody>
                                                <tr>
                                                    <td colSpan="4">
                                                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                            Combination of Subject sougth
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Typography variant="body2" style={{ fontWeight: 700 }} gutterBottom>
                                                            Core Course
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            Major Core: {this.props.major_1.label}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Minor 1: {this.props.compulsory_1.label}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Minor 2: {this.props.compulsory_2.label}
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            Minor 3: {this.props.compulsory_3.label}
                                                        </Typography>
                                                    </td>


                                                    <td style={{ verticalAlign: 'top' }}>
                                                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                            Ability Enhancement Course
                                                        </Typography>


                                                        <Typography variant="body2">
                                                            {this.props.mil.label}
                                                        </Typography>
                                                        <br />
                                                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                            Multi Disciplinary Course
                                                        </Typography>


                                                        <Typography variant="body2">
                                                            {this.props.generic_1.label}
                                                        </Typography>
                                                        <br />
                                                        
                                                    </td>


                                                    <td style={{ verticalAlign: 'top' }}>
                                                       

                                                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                           Value Added Course
                                                        </Typography>


                                                        <Typography variant="body2">
                                                            {this.props.generic_2.label}
                                                        </Typography>

                                                        <br />

                                                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                           Skill Enhancement Course
                                                        </Typography>


                                                        <Typography variant="body2">
                                                            {this.props.generic_3.label}
                                                        </Typography>
                                                        

                                                    </td>



                                                    {/*

                                                     */}
                                                </tr>
                                            </tbody>

                                        </table>) : null}

                                        {this.props.course === 'BBA' ? (<table width="100%" className="table">

                                            <tbody>
                                                <tr>
                                                    <td colSpan="4">
                                                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                            Combination of Subject sougth
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Typography variant="body2" style={{ fontWeight: 700 }} gutterBottom>
                                                            Core Course
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            Core 1: {this.props.compulsory_1.label}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Core 2: {this.props.compulsory_2.label}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Core 3: {this.props.compulsory_3.label}
                                                        </Typography>


                                                    </td>





                                                    <td style={{ verticalAlign: 'top' }}>
                                                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                            Ability Enhancement Course
                                                        </Typography>


                                                        <Typography variant="body2">
                                                            {this.props.mil.label}
                                                        </Typography>
                                                        <br />
                                                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                            Multi Disciplinary Course
                                                        </Typography>


                                                        <Typography variant="body2">
                                                            {this.props.generic_1.label}
                                                        </Typography>
                                                        <br />

                                                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                                                            Value Added Course
                                                        </Typography>


                                                        <Typography variant="body2">
                                                            {this.props.generic_2.label}
                                                        </Typography>

                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>) : null}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={3} align="center">
                        <div className="passport-photo">
                            <ImageViewer
                                data={this.props.dp}
                            />
                        </div>

                    </Grid>

                </Grid>

                <br />

                <table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td width={`10%`}>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Name of the Applicant
                                </Typography>
                            </td>
                            <td width={`15%`}>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.name}
                                </Typography>
                            </td>
                            <td width={`10%`}>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Phone No
                                </Typography>
                            </td>
                            <td width={`15%`}>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.phone}
                                </Typography>
                            </td>
                            <td width={`10%`}>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Email
                                </Typography>
                            </td>
                            <td width={`15%`}>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.email}
                                </Typography>
                            </td>

                            <td width={`10%`}>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Whatsapp No
                                </Typography>
                            </td>
                            <td width={`15%`} >
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.whatsapp_no}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Gender
                                </Typography>
                            </td>
                            <td >
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.gender}
                                </Typography>
                            </td>

                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Date of Birth
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.good_date_of_birth}
                                </Typography>
                            </td>
                            <td>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Age as on 01-01-2022
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.age}
                                </Typography>
                            </td>

                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Caste
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.caste}
                                </Typography>
                            </td>

                        </tr>

                        <tr>
                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Guardian's/Father Name
                                </Typography>
                            </td>
                            <td >
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.father_name}
                                </Typography>
                            </td>

                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Father/Guardians Qualification
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.father_qualification}
                                </Typography>
                            </td>

                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Father/Guardian's Profession
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.father_profession}
                                </Typography>
                            </td>
                            <td>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Father/Guardian's Phone
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.father_contact}
                                </Typography>
                            </td>
                        </tr>


                        <tr>
                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Mother Name
                                </Typography>
                            </td>
                            <td >
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.mother_name}
                                </Typography>
                            </td>

                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Mother's Qualification
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.mother_qualification}
                                </Typography>
                            </td>

                            <td >
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Mother's Profession
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.mother_profession}
                                </Typography>
                            </td>
                            <td>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Mother's Phone
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.mother_contact}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Nationality
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.nationality}
                                </Typography>
                            </td>
                            <td>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Religion
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.religion}
                                </Typography>
                            </td>


                            <td>
                                <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                                    Differently abled
                                </Typography>
                            </td>
                            <td width="30%" colSpan={3}>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.differently_abled}
                                </Typography>
                            </td>

                        </tr>


                    </tbody>
                </table>
                <table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td width="25%" style={{ verticalAlign: 'top' }}>
                                <Typography
                                    style={{
                                        fontWeight: '18px',
                                        fontWeight: 500
                                    }}

                                >
                                    Bank Details
                                </Typography>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '12px', fontWeight: 400 }}
                                >
                                    Bank Name: {this.props.bank_name}<br />
                                    Branch: {this.props.bank_branch}<br />
                                    A/C No: {this.props.bank_account}<br />
                                    IFSC: {this.props.bank_ifsc}<br />
                                    MICR Code: {this.props.micr_code}
                                </Typography>

                            </td>
                            <td width="25%" style={{ verticalAlign: 'top' }}>
                                <Typography
                                    style={{
                                        fontWeight: '18px',
                                        fontWeight: 500
                                    }}

                                >Present Address
                                </Typography>

                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '12px', fontWeight: 400 }}
                                >
                                    Village/Town: {this.props.present_address.pre_village}<br />
                                    P.O: {this.props.present_address.pre_po}<br />
                                    Dist: {this.props.present_address.pre_dist}, State: {this.props.present_address.pre_state}<br />
                                    Pin: {this.props.present_address.pre_pin}, Contact: {this.props.present_address.pre_contact}
                                </Typography>
                            </td>

                            <td width="25%" style={{ verticalAlign: 'top' }}>
                                <Typography
                                    style={{
                                        fontWeight: '18px',
                                        fontWeight: 500
                                    }}

                                >Permanent Address
                                </Typography>

                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '12px', fontWeight: 400 }}
                                >
                                    Village/Town: {this.props.permanent_address.per_village}<br />
                                    P.O: {this.props.permanent_address.per_po}<br />
                                    Dist: {this.props.permanent_address.per_dist}, State: {this.props.permanent_address.per_state}<br />
                                    Pin: {this.props.permanent_address.per_pin}, Contact: {this.props.permanent_address.per_contact}
                                </Typography>
                            </td>

                            <td width="25%" style={{ verticalAlign: 'top' }}>
                                <Typography
                                    style={{
                                        fontWeight: '18px',
                                        fontWeight: 500
                                    }}

                                >Local Guardians' Details
                                </Typography>

                                <Typography
                                    variant="body2"
                                    style={{ fontSize: '12px', fontWeight: 400 }}
                                >
                                    Name: {this.props.local_g}<br />
                                    Village/Town: {this.props.local_address.g_village}<br />
                                    P.O: {this.props.local_address.g_po}<br />
                                    Dist: {this.props.local_address.g_dist}, State: {this.props.local_address.g_state}<br />
                                    Pin: {this.props.local_address.g_pin}, Contact: {this.props.local_address.g_contact}<br />
                                    LAC: {this.props.permanent_address.per_lac}
                                </Typography>
                            </td>


                        </tr>
                    </tbody>
                </table>

                <table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td>
                                <Typography style={{
                                    fontWeight: '18px',
                                    fontWeight: 500
                                }}>
                                    Examination Passsed
                                </Typography>
                            </td>
                            <td align="center">
                                <Typography>
                                    Board / University
                                </Typography>
                            </td>
                            <td align="center">
                                <Typography>
                                    Roll &amp; No.
                                </Typography>
                            </td>
                            <td align="center">
                                <Typography>
                                    Year
                                </Typography>
                            </td>
                            <td align="center">
                                <Typography>
                                    Institution
                                </Typography>
                            </td>
                        </tr>
                        {this.props.exam_passed.map((el, index) =>

                            <tr key={index}>
                                <td align="center">{el.examination_passed}</td>
                                <td align="center">{el.board}</td>
                                <td align="center">{el.roll}</td>
                                <td align="center">{el.year}</td>
                                <td align="center">{el.institution}</td>
                            </tr>

                        )}
                    </tbody>

                </table>

                {this.props.course === 'TDC' ? (

                    <div>
                        <table className="table" width="100%">
                            <tbody>
                                <tr>
                                    <td colSpan="5">
                                        <Typography variant="body2" >
                                            Best of three Science / Arts Subjects
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>

                            <tbody>

                                <tr>
                                    <td>
                                        <Typography>
                                            Subjects
                                        </Typography>
                                    </td>
                                    {this.props.best_subjects.map((el, index) => {
                                        if (el.subject !== '') {
                                            return <td key={index} align="right">
                                                <Typography>
                                                    {el.subject}
                                                </Typography>
                                            </td>
                                        }
                                    }


                                    )}
                                    <td align="right">
                                        <Typography>
                                            Total
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography>
                                            Total Marks
                                        </Typography>
                                    </td>
                                    {this.props.best_subjects.map((el, index) => {
                                        total_marks = parseFloat(el.total_marks) + total_marks;
                                        return (
                                            <td key={index} align="right">
                                                <Typography>
                                                    {el.total_marks}
                                                </Typography>
                                            </td>
                                        )
                                    }

                                    )}
                                    <td align="right">
                                        <Typography>
                                            {total_marks}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography>
                                            Obtained Marks
                                        </Typography>
                                    </td>
                                    {this.props.best_subjects.map((el, index) => {
                                        marks_secured = parseFloat(el.marks_secured) + marks_secured;
                                        return (
                                            <td key={index} align="right">
                                                <Typography>
                                                    {el.marks_secured}
                                                </Typography>
                                            </td>
                                        )
                                    }
                                    )}
                                    <td align="right">
                                        <Typography>
                                            {marks_secured}
                                        </Typography>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                ) : null}


                <div>
                    <table className="table" width="100%">
                        <tbody>
                            <tr>
                                <td colSpan={parseFloat(this.props.last_marks.length) + 2}>
                                    <Typography variant="body2" >
                                        Marks Secured in the last examination passed
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>

                        <tbody>

                            <tr>
                                <td>
                                    <Typography>
                                        Subjects
                                    </Typography>
                                </td>

                                {this.props.last_marks.map((el, index) => {
                                    console.log(this.props.last_marks)
                                    if (el.subject !== '') {
                                        return <td key={index} align="right">
                                            <Typography>
                                                {el.subject}
                                            </Typography>
                                        </td>
                                    }
                                }

                                )}
                                <td align="right">
                                    <Typography>
                                        Total
                                    </Typography>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <Typography>
                                        Total Marks
                                    </Typography>
                                </td>
                                {this.props.last_marks.map((el, index) => {
                                    total_marks = parseFloat(el.total_marks) + total_marks;
                                    return (
                                        <td key={index} align="right">
                                            <Typography>
                                                {el.total_marks}
                                            </Typography>
                                        </td>
                                    )
                                }

                                )}
                                <td align="right">
                                    <Typography>
                                        {this.props.total_marks}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography>
                                        Obtained Marks
                                    </Typography>
                                </td>
                                {this.props.last_marks.map((el, index) => {
                                    marks_secured = parseFloat(el.marks_secured) + marks_secured;
                                    return (
                                        <td key={index} align="right">
                                            <Typography>
                                                {el.marks_secured}
                                            </Typography>
                                        </td>
                                    )
                                }
                                )}
                                <td align="right">
                                    <Typography>
                                        {this.props.marks_obtained}
                                    </Typography>
                                </td>
                            </tr>
                            <tr><td colSpan={this.props.last_marks.length} align="right"><Typography>Percentage</Typography></td><td colSpan="2">{this.props.percentage}</td></tr>
                        </tbody>
                    </table>
                </div>











                {/* {this.props.course === 'HS' ? (

    <table width="100%" className="table">

        <tbody>
            <tr>
                <td colSpan="4">
                    <Typography>
                        14. Combination of Subjects sougth for HS Course
                </Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography
                        variant="body2"
                    >
                        Compulsory: {this.props.compulsory_1.label}
                    </Typography>
                </td>

                <td colSpan="2">
                    <Typography
                        variant="body2"
                    >
                        MIL: {this.props.mil.label}
                    </Typography>
                </td>
            </tr>
            <tr>
                <td colSpan="4">
                    <Grid container spacing={16}>
                        {this.props.optional_subjects.map((el, index) =>

                            <Grid item lg={6} md={6} sm={6} xs={6} key={index}>
                                <Typography variant="body2" >
                                    {index + 1} : {el.label}
                                </Typography>
                            </Grid>

                        )}
                    </Grid>
                </td>
            </tr>
        </tbody>

    </table>) : null}


<br /> */}


                <table className="table" width="100%">
                    <tbody>


                        <tr>
                            <td width="20%">
                                <Typography>
                                    Extra-Curricular Activity
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.extra_curr}
                                </Typography>
                            </td>

                            <td width="20%">
                                <Typography>
                                    Computer Course
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.computer_course}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td width="20%">
                                <Typography>
                                    Activities Enrollment
                                </Typography>
                            </td>
                            <td width="80%" colSpan={3}>

                                {Array.isArray(this.props.activities) && this.props.activities.length > 0 ? <FormGroup row>
                                    {this.props.activities.map((el) =>
                                        <FormControlLabel

                                            control={
                                                <Checkbox
                                                    checked={el.status}

                                                    name={el.value}
                                                    color="primary"
                                                    readOnly={true}
                                                />
                                            }
                                            label={el.label}
                                        />
                                    )}

                                </FormGroup> : 'Not found'}


                            </td>
                        </tr>

                        <tr>
                            <td width="20%">
                                <Typography>
                                    Do you have Gap ?
                                </Typography>
                            </td>
                            <td width="30%" >
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.has_gap}
                                </Typography>
                            </td>


                            <td width="20%">
                                <Typography>
                                    Shift
                                </Typography>
                            </td>
                            <td width="30%" >
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.shift}
                                </Typography>
                            </td>


                        </tr>




                    </tbody>
                </table>

                {this.props.has_gap === 'YES' && <table className="table" width="100%">
                    <tbody>


                        <tr>
                            <td width="20%">
                                <Typography>
                                    Have you taken admission in any other college/institute?
                                </Typography>
                            </td>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.gap_admission}
                                </Typography>
                            </td>

                            {this.props.gap_admission === 'YES' && <td width="20%" colSpan={2}>
                                <Typography>
                                    Institute: {this.props.gap_institute}<br />
                                    Course: {this.props.gap_course}<br />
                                    Enrollment/Reg No: {this.props.gap_roll_no}<br />

                                </Typography>
                            </td>
                            }
                        </tr>

                        <tr>
                            <td width="20%">
                                <Typography>
                                    Reason
                                </Typography>
                            </td>
                            <td width="30%" colSpan={2}>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.gap_reason}
                                </Typography>
                            </td>


                        </tr>


                    </tbody>
                </table>}


            </div>
        )
    }
}


class ImageViewer extends Component {
    state = {
        file_id: '',
        data: '',
        isLoaded: false
    }

    __getFile = (file_id) => {
        GetData(`/${file_id}/getfilesingle`)
            .then((resp) => {
                if (resp.hasOwnProperty('mime') && resp.hasOwnProperty('data')) {
                    let file = '';
                    if (resp.mime === 'image/jpeg' || resp.mime === 'image/png' || resp.mime === 'image/jpg') {
                        file = `data:${resp.mime};base64,${resp.data}`;
                    }
                    this.setState({
                        data: file,
                        isLoaded: true
                    })
                }
            })
    }

    componentDidMount() {
        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            if (data[0].hasOwnProperty('file_id')) {
                this.__getFile(data[0].file_id)
            }
        }
    }

    render() {
        return (
            <div>
                {this.state.isLoaded ? <img
                    alt="sig-photo"
                    src={this.state.data}
                    style={{ width: '100%', height: '100%' }}

                /> : null}
            </div>
        )
    }
}